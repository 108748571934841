import { useEffect } from "react";
import SimpleSlider from "./components/Banner";
import Hero1 from "./components/Hero1";
import TopNav from "./components/NavBar/Nav";
import Footer from "./components/NavBar/Footer";
import Courses from "./components/Courses/courses";
import About from "./components/About";
import Contact from "./components/Contact/Contact";
import Gallery from "./components/Gallery/Gallery";

function GalleryAll() {

  useEffect(()=>{
    document.title = 'Gallery - Kornerston Academy Chennai';
  })

  return (
    <div>
      <TopNav />
      {/* <SimpleSlider />
      <Hero1 /> */}
      <Gallery />

      {/* <CoursesDetails /> */}
      {/* <About /> */}
      {/* <Contact /> */}
      <Footer />
    </div>
  );
}

export default GalleryAll;
