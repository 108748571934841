import React, { useState, useEffect } from "react";

import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ListItemIcon from "@mui/material/ListItemIcon";
import DoubleArrowSharpIcon from "@mui/icons-material/DoubleArrowSharp";

const useStyles = makeStyles({
  root: {},
  cardHead: {
    fontWeight: "bold",
    fontSize: "22px",
    color: "#1d1d1ede",
  },
  cardBody: {
    fontWeight: "600",
    fontSize: "13px",
    color: "#4c4c4cde",
  },
  userCount: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginRight: "12px",
    padding: "0px 8px",
  },
  favCount: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
});

export default function RecipeReviewCard() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const slides = [
    {
      image: "/assets/img/testimonial/test1.jpg",
      text: "From my IELTS preparation to getting my Canada Study Permit Visa granted within a span of 4 months, the procedure I experienced with Kornerston Academy&#39;s Overseas Education Consultant was very rapid and hassle-free. If you&#39;re preparing for an English Proficiency Test or moving forwards with an application for overseas education, I would definitely recommend Kornerston Academy&#39;s Overseas Education Consultant.",
      name: "Balachandar Muralitharan",
      position: "Global Business Management",
      place: "Conestoga College, Canada",
    },
    {
      image: "/assets/img/testimonial/test2.jpg",
      text: "I had a good experience in kornerston Thanks to Betty mam and Sunitha Mam for the support throughout the process, they trained me very well for the IELTS exam. They conducted mock tests and interviews every week, which gave me a lot of confidence and helped me to achieve the targeted score. Finally, I got my Visa approved and now it feels very happy about my future. Special thanks to Eban sir for the throughout help in the course selection and visa process. I highly recommend everyone to join Kornerston academy for successful results. Thank You.",
      name: "Jaiman Manivannan",
      position: "Quality Assurance - Manufacturing and Management",
      place: "Conestoga College, Canada",
    },
    {
      image: "/assets/img/testimonial/test3.jpg",
      text: "Hello Everyone, this is Kavya, I am currently a Project Management student at Lambton College, Toronto. I would like to start by thanking Eban and Betty for being so responsible and helping me in reaching Canada as a student. I will definitely recommend Kornerston Academy to everyone who wishes to study abroad. Betty and Eban were very helpful, and they guided me throughout the process till I reach Canada. As a student with no international experience, I was really worried and had a lot of questions about international education and college, Betty cleared all my doubts and answer every call and message even during weekends and late nights. She also arranged one to one discussions with the college representatives in Canada, when I said that I want to speak to the college directly. To the readers, you can blindly trust Kornerston Academy and its service without any further thought. Betty and Eban are there for you to help you in succeeding in your career. Trust me.",
      name: "Kavya Jyothi",
      place: "Lambton College, Canada",
      position: "Advanced Project Management and Strategic Leadership",
    },
    {
      image: "/assets/img/testimonial/test4.jpg",
      text: "I take immense pleasure in sharing my experience with Kornerston. Kornerston has played a significant role by helping me to aspire my dream which is s turning point in my life. Kornerston trained and coached me to get required score in IELTS for my higher studies. I had a clear ambition of pursuing Culinary Arts – Italian (Postgraduate) in George Brown College, Canada. I had a discussion with Mr. Eban and Mrs. Betty regarding my application and visa process. They were crystal clear about the process and gave clarification over all the concerns & queries. Once we initiated the process, I just had to follow the instruction from Mr. Eban who travelled along with me throughout the process. At one point I got admission and even paid my tuition fees but was waiting for visa approval. I did not get my visa until 20th May 2022which was the deadline to apply for deferment. With no choice left I applied for it and got mu deferral approved to May 2023. In just couple of days, I got into a much precarious situations when my visa came through without knowing how to proceed further. Then I emailed to the college requesting them to cancel the deferment but to my shock I was denied. My family and Mr. Eban had long hours call to analyse the best of the situation and he also helped me to draft the email and putting forth my difficult situation. On the very same day my deferment was cancelled, and I was asked to join the college on 10 June 2022. Kornerston is not just an academy, but they build the lives of the people. The strenuous efforts taken by them is the formula for their success. I and family have reached out to Mr. Eban even in their midnight and odd hours but still he was accessible. I can’t thank enough of all the help that Kornerston has extended to me which was beyond the business relationship. They have made a lasting impression in my life and Career.",
      name: "Mark Jonathan",
      place: "George Brown College, Canada",
      position: "Culinary Arts – Italian (Postgraduate)",
    },
    {
      image: "/assets/img/testimonial/test5.jpg",
      text: "During the course of my institution (Kornerstone Academy) experience, I believe that they greatly improved my skill in listening, writing, reading and speaking. In the beginning I was worried about time management but with their continuous mock test method help me to soared in IELTS band as well as time management. The specialty of the Kornerstone is their one-on-one method which helps the tutor to know more about the student performance. In the first attempt I scored overall 6.5 in IELTS. They also took care of my college admission and visa process. Now I am going to do my degree in supply chain management in Cape Breton University (CANADA)",
      name: "Mughil Ravichandran",
      place: "Cape Breton University, Canada",
      position: "Supply Chain Management",
    },
    {
      image: "/assets/img/testimonial/test6.jpg",
      text: "It was a great journey with Kornerston Academy. I planned for my studies in Canada, and they guided me from start to end. I also did my IELTS coaching with them and cleared with required band score. Betty and Eban were very helpful throughout. I really recommend them to students for hassle free process. Thank you, Betty and team, for all your sincere efforts. I appreciate it❤️",
      name: "Pooja Jayaprakasam",
      position: "Global Business Management",
      place: "Humber College, Canada",
    },
    {
      image: "/assets/img/testimonial/test7.jpg",
      text: "My journey throughout the process of starting my international education was a roller coaster ride for me but thanks to Betty mam and Eban sir for constantly supporting me in every ways possible . Without them everything would have been very difficult for me . I would always recommend Kornerston Academy for starters who are in a dream for starting their career",
      name: "Pramod Rama Naik",
      position: "Graduate Certificate in Information Technology",
      place: "Algoma University, Canada",
    },
    {
      image: "/assets/img/testimonial/test8.jpg",
      text: "I am Priya Dharshini from Tambaram, I had a great experience with Kornerston Academy. They have helped me through the toughest phase of my life. They have handled all my queries politely and gave me loads of options and helped me to choose wisely. I am very thankful to Mr. Eban Williams who helped me to choose one of the best colleges in Canada and also helped me throughout the process. Kornerston Academy is one of the best coaching centers for IELTS in Chennai. All staffs are experts, approachable and gives attention to each student. I really liked the learning techniques and methods. Mrs. Betty Williams helped me throughout the training, her feedbacks on regular basis helped me to improve better. I am very thankful to her and other staffs for helping me to clear IELTS.",
      name: "Priya Dharshini Pallpandiyan",
      position: "Global Hospitality Management",
      place: "Conestoga College, Canada",
    },
    {
      image: "/assets/img/testimonial/test9.jpg",
      text: "I am writing to express my deepest appreciation and gratitude for your invaluable support in both my IELTS training and visa process. Thanks to your exceptional guidance and unwavering commitment, I have achieved success in both endeavors. Your expertise in IELTS preparation played a pivotal role in helping me acquire the necessary skills and confidence to clear the exam. Your personalized approach, comprehensive study materials, and insightful feedback given by every staff were instrumental in my progress. I am immensely grateful for the patience and dedication of the staff in ensuring that I was well-prepared for each section of the test. Moreover, I would like to extend my heartfelt thanks for your exemplary assistance in my visa application process. Your thorough understanding of the requirements, attention to detail, and timely guidance were invaluable. From the initial documentation to the final submission, you guided me at every step, alleviating any concerns or doubts I had along the way. Your efforts and expertise made the entire process smooth and successful. I am eagerly looking forward to the opportunities that lie ahead, thanks to your invaluable assistance. Wishing you continued success in your future endeavors.",
      name: "Sangeeth Ravi",
      position: "Post-Degree Diploma: Web & Mobile App (Designer)",
      place: "Langar College, Canada",
    },
    {
      image: "/assets/img/testimonial/test10.jpg",
      text: "I am a student enrolled in the university of Plymouth, United Kingdom. My journey with Kornerston began when I first went there for IELTS coaching, they have the best staff and teaching methods. It is a very friendly environment to study in and you are considered as an individual and not a whole class. They focus on shaping your English language strengths and strengthening your weakness. Apart from teaching what is required, they are very supportive as well. I personally received a lot of mental and emotional support from them. Along with the IELTS coaching they gave a good career counselling and helped me choose a course programme and a good university. They excellently handled my admission process and managed all the official works that should have been done by me, which in turn made this whole process easier for me. Kornerston really helped me get admission in a top ranked university, helped me with interview skills and much more. They skilfully managed my Immigration process as well, followed up with me to do what is required to get my visa. So, to put it all in a nutshell. They have the best coaching when it comes to English assessments and a very excellent service with admission and immigration. And the best part is the communication, they always keep you posted and whenever you have anything to sort, they respond immediately. I would recommend Kornerston 10/10.",
      name: "Sangeetha Devan",
      position: "MSc Advanced Professional Practice in Occupational Therapy",
      place: "University of Plymouth, United Kingdom",
    },
    {
      image: "/assets/img/testimonial/test11.jpg",
      text: "I approached Kornerston academy with the expectation of good infrastructure, assistance, friendly approach. No doubt the Kornerston academy meets all the expectations. I thank and appreciate the support provided by the staff members and the faculty. They're assistants and ideas throughout the process of preparing me for my IELTS examination, helping me in selecting colleges and making suggestions of suitable courses to pursue in Canada was amazing. I would love to thank Mr. Eban Williams and Mrs. Betty Jerusha Williams for helping me pursue my dreams",
      name: "Suneel Albert Nappoli",
      position: "Supply Chain Management",
      place: "Cape Breton University, Canada",
    },
    {
      image: "/assets/img/testimonial/test12.png",
      text: "I thank you for the wonderful experience with your academy. Without your help it wouldn’t have been possible for me to achieve my dream. I came to crack my IELTS test only but you helped me from scoring a good mark in my IELTS to getting into a prestigious university to getting the loan sanctioned and applying my visa and most importantly the documentation for visa very professional, that’s the reason for me to get approval. Throughout the process the team guided me very well and were very responsive and deemed it their responsibility to help me pursue my career in Canada.",
      name: "Melvin Samuel Joshua",
      position: "Melvin Samuel Joshua",
      place: "Conestoga College, Canada",
    },
    {
      image: "/assets/img/testimonial/test13.jpg",
      text: "I'm writing this with utmost joy that my Ireland visa got approved. After lots of struggle and patience, with your help it wouldn't be possible. You are the reason for my application to Ireland after last year setback with Canada visa refusal. Betty mam and you pushed me to apply for UK or Ireland and now here I'm planning to reach Ireland by end of August to pursue my Master's degree in University of Galway. Thank you so much for properly guiding me in preparing visa documents. Kornerston has helped me achieve my dream and I wish you all the best for shaping upcoming students to achieve their goals. Thank you once again.",
      name: "Vinoth Selvaraj",
      position: "Master of Science (Information Systems Management)",
      place: "University of Galway, Ireland",
    },
    {
      image: "/assets/img/testimonial/test14.jpg",
      text: "I am incredibly grateful for the Kornerston academy's invaluable assistance throughout my journey, from securing admission to the University of Arizona to navigating the complex visa processes. Their unwavering support and expert guidance were instrumental in making this dream a reality. They provided comprehensive knowledge, personalized attention, and meticulous assistance every step of the way. I highly recommend their services to any aspiring student seeking seamless support and success in their academic endeavors.",
      name: "Vinu Kevin Diesel",
      position: "MS Data Science",
      place: "University of Arizona, USA",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const [dragStartX, setDragStartX] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [hovering, setHovering] = useState(false);

  const handleMouseEnter = () => {
    setHovering(true);
  };

  const handleMouseLeave = () => {
    setHovering(false);
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const visibleDots = 3; // Number of visible dots at a time

  const startIndex = Math.max(0, currentIndex - 1); // Start index of visible dots
  const endIndex = Math.min(slides.length - 1, startIndex + visibleDots - 1); // End index of visible dots

  const visibleSlides = slides.slice(startIndex, endIndex + 1);

  const handleDragStart = (e) => {
    setDragStartX(e.clientX);
    setDragging(true);
  };

  const handleDragEnd = (e) => {
    if (!dragging) return;

    const dragEndX = e.clientX;
    const dragDistance = dragEndX - dragStartX;

    if (dragDistance > 50) {
      goToPrevious();
    } else if (dragDistance < -50) {
      goToNext();
    }

    setDragging(false);
  };

  const autoplayDelay = 5000;
  useEffect(() => {
    let autoplayInterval = null;

    if (!hovering) {
      autoplayInterval = setInterval(goToNext, autoplayDelay);
    }

    return () => clearInterval(autoplayInterval);
  }, [currentIndex, hovering]);

  return (
    <div className="course-area section-padding">
      <div className="container">
        <div className="row mt-70 mb-20">
          <div className="col-md-8">
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab label="Canada" value="1" />
                    <Tab label="UK" value="2" />
                    <Tab label="USA" value="3" />
                    <Tab label="Australia" value="4" />
                    <Tab label="New Zealand" value="5" />
                    <Tab label="Germany" value="6" />
                  </Tabs>
                </Box>
                <TabPanel value="1">
                  <div
                    style={{
                      backgroundImage: "url('assets/img/flag/ca.jpg')",
                      backgroundRepeat: "no-repeat",
                      // backgroundAttachment: "fixed",
                      backgroundPosition: "center",
                    }}
                  >
                    <Box className="overseas-text">
                      <Typography variant="h4">Why Study in Canada</Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        With affordable tuition fees, quality institutions, flexibility to work during study,
post-study work and immigration options, and welcoming people, it is no
surprise that Canada is one of the premiere destinations for international
students, drawing in over 300,000 new international students annually.
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        According to the Canadian Bureau for International Education’s (CBIE) 2018
International Student Survey, the overwhelming majority of students (93%) say
they are satisfied with their educational experience in Canada
                      </Box>

                      <Typography variant="h4" className='pt-20 pb-20'>
                        Academic Excellence
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        One of the biggest reasons students choose to come to Canada is the quality of
a Canadian education.
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Canadian degrees and diplomas are widely recognized as comparable to those
from the United States, Australia, and the United Kingdom.
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Canadian universities and colleges consistently place high on international
rankings. In fact, 17 Canadian universities are ranked in the top 500 of the QS
World University Rankings 2020. Many are also ranked in the top 100 by
reputable sources like The Times Higher Education World University Rankings
and Shanghai Ranking’s Academic Ranking of World Universities. Education is
highly valued in Canada.
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        The Canadian government invests heavily in the Canadian education system.
As a result, Canada has one of the highest rates of post secondary education
completion (61%) amongst the OECD member countries. Unsurprisingly,
Canadian teachers, professors, and researchers are also amongst the most
qualified academic professionals around the world. Canada also attracts top
international faculty because of its investments in cutting-edge research in
areas such as medical science, pharmaceuticals, information technology and
artificial intelligence.
                      </Box>
                      </Box>
                    <Box className="overseas-text">
                      <Typography variant="h4">
                        Our Services for students who wish to study in Canadian
                        Universities or Canadian SPP Colleges:
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Pre-counseling about various courses available in
                        different Universities In Canada and Colleges In Canada
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Providing information about Tuition fees, living costs,
                        Admission intakes and application deadlines
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Preparing the students to take IELTS, TOEFL, GRE and
                        GMAT tests etc successfully.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Obtaining Admissions in Canadian Universities or
                        Canadian Colleges.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Canadian University Scholarship options sourced through
                        the Universities or through the respective Government.
                      </Box>

                      <Typography>
                        Please note that we do not charge any service fee from
                        students who wish to pursue their higher studies in
                        Canadian Universities or in Canadian Colleges.
                      </Typography>
                    </Box>
                  </div>
                </TabPanel>
                <TabPanel value="2">
                  <Box
                    style={{
                      backgroundImage: "url('assets/img/flag/kingdom.jpg')",
                      backgroundRepeat: "no-repeat",
                      // backgroundAttachment: "fixed",
                      backgroundPosition: "center",
                    }}
                  >
                     <Box className="overseas-text">
                      <Typography variant="h4">
                        {" "}
                        STUDY IN UK – ADVANTAGE
                      </Typography>
                      <Typography variant="subtitle1">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Universities in UK, provide the option of students
                        undertaking internships for one year as part of their
                        academic projects in UK.
                        </Box>
                        <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                         Most of the Masters degree
                        courses are only one year duration and hence the cost is
                        comparatively low. 
                        </Box>
                        <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        The United Kingdom Student Visa
                        department wants to ensure, that there is a genuine
                        interest from the student to study in United Kingdom.
                        </Box>
                       
                      </Typography>
                    </Box>

                    <Box className="overseas-text">
                      <h4>
                        Our Services for students who wish to study in
                        Universities in United Kingdom or Colleges in United
                        Kingdom include:
                      </h4>
                      <Typography variant="subtitle1">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Pre-counseling about various courses available in
                        different Universities in UK and colleges in UK.
                        </Box>
                        <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Providing information about Tuition fees, living costs,
                        Admission intakes and application deadlines. 
                        </Box>
                        <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Preparing
                        the students to take IELTS test, Pearson PTE English
                        test etc.
                        </Box>
                        <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                         Obtaining admissions in United Kingdom
                        Universities or United Kingdom Colleges (Application fee
                        is waived in most instance, if applied through us).
                        </Box>
                        <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        University Scholarship options sourced through the
                        Universities or through the respective Government.
                        </Box>
                        <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Guiding the students and their parents to prepare the
                        documents for the United Kingdom student visa.
                        </Box>
                        <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                         Assisting
                        to get the overseas education loan from banks in India.
                        </Box>

                        <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Arranging accommodation in United Kingdom and also
                        assisting to get part time jobs in United Kingdom.
                        </Box>
                       
                        <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Obtaining the United Kingdom health insurance for
                        students.
                        </Box>
                      </Typography>
                    </Box>
                   
                  </Box>
                </TabPanel>
                <TabPanel value="3">
                  <Box
                    style={{
                      backgroundImage: "url('assets/img/flag/usa.jpg')",
                      backgroundRepeat: "no-repeat",
                      // backgroundAttachment: "fixed",
                      backgroundPosition: "center",
                    }}
                  >
                    <Box className="overseas-text">
                      <Typography variant="h4">
                        Our Services for students who wish to study in United
                        States of America Universities or United States of
                        America Colleges include:
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Pre-counseling about various courses available in
                        different Universities in US and colleges in US.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Providing information about Tuition fees, living costs,
                        Admission intakes and application deadlines.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Preparing the students to take IELTS, TOEFL, GRE, GMAT,
                        SAT etc.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Obtaining Admissions in Canadian Universities or
                        Canadian Colleges.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Obtaining admissions in United States of America
                        Universities or United States of America Colleges.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Concordia College New York as appoint us as their sole
                        student recruitment consultant in India.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        United States of America University Scholarship options
                        sourced through the Universities or through the
                        respective Government.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Guiding the students and their parents to prepare the
                        documents for the United States of America student visa.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Assisting to get the Bank education loan from banks in
                        India.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Arranging accommodation in United States of America and
                        also assisting to get part time jobs in United States of
                        America.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Obtaining the United States of America health insurance
                        for students.
                      </Box>

                      <Typography variant="h6" className="pt-40">
                        STUDY IN UNITED STATES OF AMERICA – ADVANTAGE
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Students are able to obtain Overseas Practical training
                        visas after the completion of their study in US
                        University.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        It is not easy for married couples to use the United
                        States of America education, as a pathway to Immigrate
                        to United States of America.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        The United States of America Student Visa department
                        wants to ensure, that there is a genuine interest from
                        the student to study in United States of America.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Most of the Universities in United States of America
                        insist on the student to take the proof of English test.
                        The waiver based on Medium of instruction in English is
                        no more appreciated.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Manipulated documents are not accepted and will be
                        treated as violation of law. Kornerston, as a reputed
                        United States of America Education Consultant will not
                        be interested to entertain these documents in any
                        process.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Our success rate is very high for Students who wish to
                        study in United States of America Universities or study
                        in United States of America colleges. This is in terms
                        of obtaining admissions in United States of America
                        Universities and also in obtaining United States of
                        America student visas.
                      </Box>
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel value="4">
                  <Box
                    style={{
                      backgroundImage: "url('assets/img/flag/aus.png')",
                      backgroundRepeat: "no-repeat",
                      // backgroundAttachment: "fixed",
                      backgroundPosition: "center",
                    }}
                  >
                    <Box className="overseas-text">
                      <Typography variant="h4">
                        Our Services for students who wish to study in
                        Australian Universities or Australian Colleges:
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Pre-counseling about courses offered by Australian
                        Universities and Australian colleges.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Providing information about Tuition fees, living costs,
                        Admission intakes and application deadlines.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Preparing the students to take IELTS test, TOEFL,
                        Pearson PTE English test, CAE etc.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Obtaining admissions in Australian Universities or
                        Australian Colleges (Application fee is waived in most
                        instance, if applied through us).
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Scholarship options sourced through the Universities or
                        through the respective Government.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Guiding the students and their parents for the
                        Australian student visa.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Assisting to get the Bank overseas education loan from
                        nationalized banks in India.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Arranging accommodation in Australia and also assisting
                        to get part time jobs in Australia.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Obtaining the Australian health insurance for students.
                      </Box>

                      <Typography variant="h6" className="pt-40">
                        STUDY IN AUSTRALIA ADVANTAGE:
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Students undertaking a two year full time study in
                        Australia become eligible to apply for 2 year post study
                        work visa in Australia. The Australian Universities and
                        the Australian Student Visa department want to ensure,
                        that there is a genuine interest from the student to
                        genuinely study in Australia.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Kornerston work in association with larger student
                        recruiting consultants, who have their head office in
                        Australia.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Admission officers from Australian Universities and
                        Australian Colleges visit our offices to meet with
                        Prospective students and to train the overseas education
                        counselors, so that the students are given transparent
                        and crystal clear information’s.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        The Australian visa offices process Student visa
                        applications very fast, if the student and his / her
                        documents are genuine. Manipulated documents are not
                        accepted and will be treated as violation of law.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Kornerston, as a reputed Australian Study Consultant
                        will not be interested to entertain these documents in
                        any process. Our success rate is 100% for Students who
                        wish to study in Australian Universities or study in
                        Australian colleges.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Kornerston does not take any service charges from
                        students for Australian admissions process or for
                        Australian Student Visa process.
                      </Box>
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel value="5">
                  <Box
                    style={{
                      backgroundImage: "url('assets/img/flag/nz.png')",
                      backgroundRepeat: "no-repeat",
                      // backgroundAttachment: "fixed",
                      backgroundPosition: "center",
                    }}
                  >
                    <Box className="overseas-text">
                      <Typography variant="h4">
                        Our Services for students who wish to study in New
                        Zealand Colleges or Universities include:
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Pre-counseling about various courses available in
                        different Universities in New Zealand and colleges in
                        New Zealand.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Providing information about Tuition fees, living costs,
                        Admission intakes and application deadlines.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Preparing the students to take IELTS test, Pearson PTE
                        English test etc.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Obtaining admissions in New Zealand Universities or New
                        Zealand Colleges (Application fee is waived in most
                        instance, if applied through us).
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        New Zealand University Scholarship options sourced
                        through the Universities or through the respective
                        Government.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Guiding the students and their parents to prepare the
                        documents for the New Zealand student visa.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Assisting to get the Bank education loan from banks in
                        India.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Arranging accommodation in New Zealand and also
                        assisting to get part time jobs in New Zealand.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Obtaining the New Zealand health insurance for students.
                      </Box>

                      <Typography>
                        Please note that we do not charge any service fee from
                        students who wish to pursue their higher studies in New
                        Zealand Universities or New Zealand Colleges.
                      </Typography>

                      <Typography variant="h6" className="pt-40">
                        STUDY IN NEW ZEALAND ADVANTAGE:
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Students undertaking a one year (Level 8 or Level 9)
                        full time study in New Zealand, become eligible to apply
                        for 3 year post study work permit.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Students undertaking a one year (Level 7)full time study
                        program in New Zealand are eligible for a one year Post
                        study work permit in New Zealand.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        It is not easy for married couples to use the New
                        Zealand education, as a pathway to Immigrate to New
                        Zealand.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        The New Zealand Student Visa department wants to ensure,
                        that there is a genuine interest from the student to
                        study in New Zealand.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Most of the Universities in New Zealand insist on the
                        student to take the proof of English test. The waiver
                        based on Medium of instruction in English is no more
                        appreciated.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Manipulated documents are not accepted and will be
                        treated as violation of law. Kornerston, as a reputed
                        New Zealand Education Consultant will not be interested
                        to entertain these documents in any process.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Our success rate is 100% for Students who wish to study
                        in New Zealand Universities or study In New Zealand
                        colleges. This is in terms of obtaining admissions in
                        New Zealand Universities and also in obtaining New
                        Zealand student visas.
                      </Box>
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel value="6">
                  <Box
                    style={{
                      backgroundImage: "url('assets/img/flag/ger.jpg')",
                      backgroundRepeat: "no-repeat",
                      // backgroundAttachment: "fixed",
                      backgroundPosition: "center",
                    }}
                  >
                    <Box className="overseas-text">
                      <Typography variant="h4">
                        Our Services for students who wish to study in Germany
                        Colleges or Universities include:
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Pre-counseling about various courses available in
                        different Universities in Germany.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Providing information about Tuition fees, living costs,
                        Admission intakes and application deadlines.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Preparing the students to take IELTS, TOEFL, GRE, GMAT
                        etc.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Guiding the students to learn basic levels of German
                        language.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Obtaining admissions in Germany Universities. Students
                        need to have over 78% with no history of arrears to
                        apply to Universities in Germany.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Guiding the students and their parents to prepare the
                        documents for the German student visa.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Assisting to get the overseas education loan from banks
                        in India.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Arranging accommodation in Germany and also assisting to
                        get part time jobs in Germany.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Obtaining the Germany health insurance for students.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Arranging accommodation in United States of America and
                        also assisting to get part time jobs in United States of
                        America.
                      </Box>

                      <Typography>
                        Please note that we do charge service fee from students
                        who wish to pursue their higher studies in German
                        Universities.
                      </Typography>

                      <Typography variant="h6" className="pt-40">
                        STUDY IN GERMANY ADVANTAGE:
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        The tuition fees is either nil or very very low to study
                        in Germany.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Students undertaking a two year full time study in
                        Germany, normally become eligible to apply for 1 year
                        work permit after their studies in Germany.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        It is not easy for married couples to use the Germany
                        education, as a pathway to Immigrate to Germany.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        The Germany Student Visa department wants to ensure,
                        that there is a genuine interest from the student to
                        study in Germany.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Most of the Universities in Germany insist on the
                        student to take the proof of English and German test.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Manipulated documents are not accepted and will be
                        treated as violation of law. Kornerston, as a reputed
                        Germany Education Consultant will not be interested to
                        entertain these documents in any process.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        mt={1}
                      >
                        <ListItemIcon>
                          <DoubleArrowSharpIcon />
                        </ListItemIcon>
                        Our success rate is very high for Students who wish to
                        study in Germany Universities. This is in terms of
                        obtaining admissions in German Universities and also in
                        obtaining German student visas.
                      </Box>
                    </Box>
                  </Box>
                </TabPanel>
              </TabContext>
            </Box>
          </div>
          <div className="bg-white col-md-4 mt-40">
            <div
              className="slider-area single-item-overseas"
              onMouseDown={handleDragStart}
              onMouseUp={handleDragEnd}
              onTouchStart={handleDragStart}
              onTouchEnd={handleDragEnd}
            >
              <div className="testimonials-overseas-header">
                <h4>TESTIMONIES</h4>
              </div>
              <div className="hero-slider owl-carousel">
                <div className="single-testimonial-area1 col-12 row-mb-12">
                  <div
                    className="carousel-container"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                      <div className="testimonies-overseas-button">
                <button className="carousel-button" onClick={goToPrevious}>
                  <i class="zmdi zmdi-chevron-left"></i>
                </button>
                    <div className="testimonial-image ">
                      <img
                        src={slides[currentIndex].image}
                        alt={`Image ${currentIndex + 1}`}
                        className="carousel-image"
                      />
                    </div> 
                <button className="carousel-button" onClick={goToNext}>
                  <i class="zmdi zmdi-chevron-right"></i>
                </button>
              </div>

                    <div className="testimonial-content">
                      <div className="testimonial-details">
                        <h4 style={{ fontWeight: "700" }}>
                          {slides[currentIndex].name}
                        </h4>
                        <p className="carousel-text">
                          {slides[currentIndex].position}
                        </p>
                        <p className="carousel-text">
                          {slides[currentIndex].place}
                        </p>
                      </div>
                      <p className="carousel-text">
                        {slides[currentIndex].text}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="testimonies-overseas-button">
                <button className="carousel-button" onClick={goToPrevious}>
                  <i class="zmdi zmdi-chevron-left"></i>
                </button>
                <button className="carousel-button" onClick={goToNext}>
                  <i class="zmdi zmdi-chevron-right"></i>
                </button>
              </div> */}
              <div className="dots-container pt-80">
                {visibleSlides.map((_, index) => (
                  <button
                    key={startIndex + index}
                    className={`dot ${
                      startIndex + index === currentIndex ? "active" : ""
                    }`}
                    onClick={() => goToSlide(startIndex + index)}
                  ></button>
                ))}
              </div>

              <div class="col-md-12 col-sm-12 mt-80 mb-80 text-center">
                <a
                  href="https://www.google.com/search?q=kornerston+academy+review&amp;oq=kornerston+bascademy&amp;aqs=chrome.1.69i57j0l5.14832j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x3a525ebc934883d9:0xb9206341ece65c7b,1,,,"
                  class="button-default button-large"
                >
                  More Testimonies <i class="zmdi zmdi-chevron-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
