import React from "react";
const About = () => {
  return (
    <div className="as-mainwrapper">
      <div className="bg-white">
        <div className="breadcrumb-banner-area">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcrumb-text">
                  <h1 className="text-center">ABOUT US</h1>
                  <div className="breadcrumb-bar">
                    <ul className="breadcrumb text-center">
                      <li>
                        <a href="index.html">Home</a>
                      </li>
                      <li>ABOUT US</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about-area mt-20">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 about-container">
                <div className="">
                  <h3 style={{ marginBottom: "0px", paddingBottom: "0px" }}>
                  <span className="orange-color"> 
                  Emergence of
                  </span>
                  </h3>
                  <h3 style={{ marginTop: "0px", paddingTop: "0px" }}>
                    KORNERSTON ACADEMY
                  </h3>
                  <p>
                    <strong>Betty Jerushah,</strong> who is passionate about teaching and with
                    rich experience in coaching students for foreign English
                    language tests, established <strong>KORNERSTON ACADEMY</strong> in the year
                    2014. She holds Master Degrees in English and Public
                    Administration along with B.Ed., She is a trained trainer,
                    <strong class="mobile-letter-spacing"> certified by British Council / IDP Australia for IELTS,
                    Pearson for PTE Academic and TOEFL.</strong>
                  </p>
                  <p>
                    She has been coaching students for <strong>PTE Academic</strong> since it’s
                    inception in India in 2014, for immigration and overseas
                    studies.
                  </p>
                  <p>
                    Here in Kornerston Academy, more than 2000 students have
                    taken coaching for English language tests in the last five
                    years. <strong>Kornerston</strong> has a proven record of students who have
                    scored 80+ and are placed all across the world, for which
                    the testimonial of the students witnesses.
                  </p>
                  <a className="button-default" href="/courses">
                    Learn Now
                  </a>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="about-image-area img-full">
                  <img
                    src="https://htmldemo.net/edubuzz/edubuzz/img/about/about1.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5">
                <div className="about-image-area img-full">
                  <img
                    src="assets/img/about/about3.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-7 about-container">
                <div className="">
                  <h3 style={{ marginBottom: "0px", paddingBottom: "0px" }}>
                  <span className="orange-color"> 
                  Significance of
                  </span>
                  </h3>
                  <h3 style={{ marginTop: "0px", paddingTop: "0px" }}>
                     KORNERSTON ACADEMY
                  </h3>
                  <p>
                    Each individual is as unique as the finger prints of people
                    in the world, making them as inimitable as possible.
                    Conceptualisation of this revelation became the nucleus for
                    the creation of Kornerston Academy, thus Catering to each
                    student in accordance to their aptitude. We believe that
                    when each individual is given enough guidance and attention
                    exclusively, students will be able to achieve their goals
                    without much impediment.
                  </p>
                  <p>
                    “Our sole objective is to attain perfection with enough
                    preparation”.
                  </p>
                  <p>
                    This has made Kornerston Academy to evolve into a
                    multifaceted, dynamic and result oriented coaching
                    institution, catering to a variety of students requiring
                    guidance and training to attain proficiency in the English
                    language.
                  </p>
                  <p>
                    We are preparing our students with enough individual
                    attention to aid them overcome specific difficulties without
                    sweat.
                  </p>
                  <a className="button-default" href="/courses" style={{marginBottom: '30px'}}>
                    Learn Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
