import { useEffect } from "react";
import SimpleSlider from "./components/Banner";
import Hero1 from "./components/Hero1";
import TopNav from "./components/NavBar/Nav";
import Footer from "./components/NavBar/Footer";
import About from "./components/About";
import Contact from "./components/Contact/Contact";

function AboutAll() {

  useEffect(()=>{
    document.title = 'About us - Kornerston Academy';
  })

  return (
    <div>
      <TopNav />
      {/* <SimpleSlider />
      <Hero1 />
      <Courses /> */}

      {/* <CoursesDetails /> */}
      <About />
      {/* <Contact /> */}
      <Footer />
    </div>
  );
}

export default AboutAll;
