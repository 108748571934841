import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
const useStyles = makeStyles({
  root: {
    backgroundColor: "blue",
    paddingBottom: 16,
    paddingRight: 16,
    marginTop: 16,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 500,
  },
  aboutContainer: {
    marginTop: "60px",
  },
});
const Hero1 = () => {
  const classes = useStyles();
  return (
    <div>
      {/* <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} sm={7}>
            <Typography variant="h3">
              Provide Best Education Services For You
            </Typography>
            <Box sx={{ mt: 3 }}>
              <Typography variant="body1">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Box
              sx={{
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              <img
                style={{ width: "100%" }}
                src="https://htmldemo.net/edubuzz/edubuzz/img/about/about1.jpg"
                alt="hero1"
              />
            </Box>
          </Grid>
        </Grid>
      </Container> */}
      <div className="about-area mt-95">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 about-container">
              <div className="">
                <h3 style={{fontSize:'20px'}}>
              
                 <span className="orange-color"> Provide Best Coaching For Foreign Entrance Exams </span>
                   IELTS / PTE Academic / TOEFL / OET / CELPIP
                </h3>
                <p>
                This has made Kornerston Academy to evolve into a multifaceted, dynamic and result oriented coaching institution, catering to a variety of students requiring guidance and training to attain proficiency in the English language.
                </p>
                <p>
                We are preparing our students with enough individual attention to aid them overcome specific difficulties without sweat.
                </p>
                <a className="button-default" href="/courses">
                  Learn More
                </a>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="about-image-area img-full">
                <img src="assets/img/about/about3.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero1;
