import React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import DoubleArrowSharpIcon from "@mui/icons-material/DoubleArrowSharp";

const Event = () => {
  return (
    <div class="event-area section-padding bg-white event-page">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="pagination-content number"></div>
          </div>
        </div>

        <div class="row mt-90">
          <div class="col-md-12">
            <div class="section-title mt-20 mb-50">
              <h3 className="text-center">WHY KORNERSTON</h3>
            </div>
            <div class="section-title-wrapper">
              <div class="section-title">
                <h3>KORNERSTON ACADEMY<span className="orange-color"> Equips on:</span></h3>
                <p>
                  IELTS / PTE Academic / OET / TOEFL coaching - Students
                  appearing for IELTS / PTE / OET / TOEFL exams are given
                  individual attention with custom-made, tailor-fit and
                  comprehensive study materials. Special care is taken to assess
                  their progress in every step of the way, evaluating them
                  along-side their training and providing the required coaching.
                </p>
                <p>
                Exam tips and advices are given with regular mock assessments to enable them to understand their strengths and weaknesses, which aids them in achieving their targets.
                </p>
              </div>
              <div class="section-title">
                <h3><span className="orange-color"> Rewards of becoming </span> KORNERSTON ACADEMY Student:</h3>
                <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          
                        }}
                        mt={1}
                      >
                       
                          <DoubleArrowSharpIcon />
                        
                        One on one coaching and assessment; Interactive and friendly environment
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        mt={1}
                      >
                        
                          <DoubleArrowSharpIcon />
                       
                        Everyday evaluation of progress with periodical “MOCK TESTS”, which gives the feel of a real exam.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        mt={1}
                      >
                      
                          <DoubleArrowSharpIcon />
                        
                        Imperative tips and exercises to enhance capability which reflects during exams.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        mt={1}
                      >
                       
                          <DoubleArrowSharpIcon />
                        
                        Additional study materials focusing in the area of weakness.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        mt={1}
                      >
                  
                          <DoubleArrowSharpIcon />
                     
                        Flexible timings, so that students can choose their own options which do not interfere with their commitments.
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        mt={1}
                      >
                      
                          <DoubleArrowSharpIcon />
                      
                        Trained faculties – with periodical training and orientation….
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        mt={1}
                      >
                      
                          <DoubleArrowSharpIcon />
                     
                        Regular introspection of improvement.
                      </Box>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-20">

          <div class="col-lg-4 col-md-6 features-img">
            <div class="single-event-item event-style-3">
              <div class="single-event-image">
              <a href="assets/img/event/idp.png" data-fancybox="images">
                  <img src="assets/img/event/1.jpg" alt="" />
                  {/* <span>15 Jun</span> */}
                </a>
              </div>
              
            </div>
          </div>
          <div class="col-lg-4 col-md-6 features-img">
            <div class="single-event-item event-style-3">
              <div class="single-event-image">
              <a href="assets/img/event/pte2.png" data-fancybox="images">
                  <img src="assets/img/event/2.jpg" alt="" />
                  {/* <span>20 Apr</span> */}
                </a>
              </div>
              
            </div>
          </div>
          <div class="col-lg-4 col-md-6 features-img">
            <div class="single-event-item event-style-3">
              <div class="single-event-image">
              <a href="assets/img/event/toefl3.png" data-fancybox="images">
                  <img src="assets/img/event/3.jpg" alt="" />
                  {/* <span>20 Apr</span> */}
                </a>
              </div>
              
            </div>
          </div>
          <div class="col-lg-4 col-md-6 features-img">
            <div class="single-event-item event-style-3">
              <div class="single-event-image">
              <a href="assets/img/event/idpKA4.png" data-fancybox="images">
                  <img src="assets/img/event/4.jpg" alt="" />
                  {/* <span>20 Apr</span> */}
                </a>
              </div>
              
            </div>
          </div>
          <div class="col-lg-4 col-md-6 features-img">
            <div class="single-event-item event-style-3">
              <div class="single-event-image">
              <a href="assets/img/event/silver5.png" data-fancybox="images">
                  <img src="assets/img/event/5.jpg" alt="" />
                  {/* <span>20 Apr</span> */}
                </a>
              </div>
             
            </div>
          </div>
          <div class="col-lg-4 col-md-6 features-img">
            <div class="single-event-item event-style-3">
              <div class="single-event-image">
              <a href="assets/img/event/BCworkshop6.png" data-fancybox="images">
                  <img src="assets/img/event/6.jpg" alt="" />
                  {/* <span>20 Apr</span> */}
                </a>
              </div>
              {/* <div class="single-event-text">
                <h3>
                  <a href="#">CERTIFICATE</a>
                </h3>
                <div class="single-item-comment-view">
                  <span>
                    <i class="zmdi zmdi-time"></i>4.00 pm - 8.00 pm
                  </span>
                  <span>
                    <i class="zmdi zmdi-pin"></i>Jessore Bangladesh
                  </span>
                </div>
              </div> */}
            </div>
          </div>
          <div class="col-lg-4 col-md-6 features-img">
            <div class="single-event-item event-style-3">
              <div class="single-event-image">
              <a href="assets/img/event/betty7.png" data-fancybox="images">
                  <img src="assets/img/event/7.png" alt="" />
                  {/* <span>20 Apr</span> */}
                </a>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Event;
