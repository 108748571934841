import * as React from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import TopNav from "../NavBar/Nav";
import Footer from "../NavBar/Footer";

const useStyles = makeStyles({
  root: {},
  cardHead: {
    fontWeight: "bold",
    fontSize: "22px",
    color: "#1d1d1ede",
  },
  cardBody: {
    fontWeight: "600",
    fontSize: "13px",
    color: "#4c4c4cde",
  },
  userCount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: "12px",
    padding: "0px 8px",
  },
  favCount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export default function BlogsLinkDoulingo() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <TopNav />
      <div class="course-area section-padding">
        <div class="container">
          <div class="row mt-50">
            <div class="col-lg-8 col-md-6 col-12">
              <div className="mt-100 blogs-title">
                <h3>Duolingo English Test</h3>
                {/* <em>Introduction</em> */}
              </div>
              <div class="single-item1">
                <div class="single-item-image">
                  <img
                    src="/assets/img/slider/Duolingo English Test.png"
                    alt=""
                  />
                </div>
                <div class="single-item-text1">
                  <p>
                    The Duolingo English Test is used as proof of English
                    proficiency by institutions and employers around the world.
                  </p>

                  <br></br>
                 

                  <p>
                    It is recognized by thousands of universities in over 50
                    countries, and it is commonly accepted for admissions,
                    placement, progress, or exit requirements.
                  </p>

                  <p>
                    The DET gauges an individual&#39;s English language
                    proficiency on a scale of 10–160.
                  </p>

                  <p>
                    One can take the test online at any preferred time and from
                    any location. The test taker is not required to visit the
                    examination centre to attempt this test. The best thing is
                    that you can take the test at your leisure, enjoying the
                    solace of your own home.
                  </p>

                  <p>
                    The test can be completed in under an hour, and the results
                    are released in two days. It has two sections: the adaptive
                    section and the video interview.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mt-100">
              <div class="single-item mb-50">
                <div class="single-item-blogsLink">
                  <h4>Courses</h4>
                </div>
                <div class="single-item-text">
                  <h4>
                    <a href="/courses-details/toefl">IELTS</a>
                  </h4>
                  <small>(International English Language Testing System)</small>
                  <h4>
                    <a href="/courses-details/toefl">PTE ACADEMIC</a>
                  </h4>
                  <small>(PTE Academic)</small>
                  <h4>
                    <a href="/courses-details/toefl">TOEFL</a>
                  </h4>
                  <h4>
                    <a href="/courses-details/toefl">OET</a>
                  </h4>
                  <small>(The Occupational English Test)</small>
                  <h4>
                    <a href="/courses-details/toefl">CELPIP</a>
                  </h4>
                  <small>
                    (Canadian English Language Proficiency Index Program)
                  </small>
                  <h4>
                    <a href="/courses-details/toefl">NAATI CCL - TAMIL</a>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-sm-12 text-center">
              <a href="/courses" class="button-default button-large">
                Browse All Courses <i class="zmdi zmdi-chevron-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
