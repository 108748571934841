import * as React from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {},
});

const courses = [
  {
    "id": 1,
    "title": "IELTS",
    "description": "IELTS (The International English Language Testing System) is the world’s most popular English language proficiency test for higher education and global migration.",
    "imageSrc": "assets/img/slider/6.jpg",
    "link": "/courses-details/ielts"
  },
  {
    "id": 2,
    "title": "PTE ACADEMIC",
    "description": "The PTE Academic is a computer-based academic English language test aimed at non-native English speakers wanting to study abroad and immigration.",
    "imageSrc": "assets/img/slider/5.jpg",
    "link": "/courses-details/pte"
  },
  {
    "id": 3,
    "title": "TOEFL",
    "description": "The TOEFL iBT® test measures your ability to use and understand English at the university level. It evaluates how well you combine your reading, listening, speaking, and writing skills.",
    "imageSrc": "assets/img/slider/7.jpg",
    "link": "/courses-details/toefl"
  }
]

export default function RecipeReviewCard() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div class="course-area section-padding">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title-wrapper">
              <div class="section-title">
                <h3>COURSES</h3>
                {/* <p>There are many variations of passages of Lorem Ipsum</p> */}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
        {courses.map((course) => (
            <div key={course.id} class="col-lg-4 col-md-6 col-12">
              <div class="single-item ">
                <div class="single-item-image overlay-effect">
                  <a href={course.link}>
                    <img src={course.imageSrc} alt="" />
                  </a>
                </div>
                <div class="single-item-text">
                  <h4>
                    <a href={course.link}>{course.title}</a>
                  </h4>
                  <p>{course.description}</p>
                  <div class="single-item-content">
                    <div class="single-item-rating1">
                      <a href={course.link}>Learn Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div class="col-md-12 col-sm-12 text-center">
            <a href="/courses" class="button-default button-large">
              Browse All Courses <i class="zmdi zmdi-chevron-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
