import { useEffect } from "react";
import SimpleSlider from "./components/Banner";
import Hero1 from "./components/Hero1";
import TopNav from "./components/NavBar/Nav";
import Footer from "./components/NavBar/Footer";
import CoursesFull from "./components/CoursesFull/CoursesFull";
import About from "./components/About";
import Contact from "./components/Contact/Contact";




function CoursesAll() {

  useEffect(()=>{
    document.title = 'IELTS/PTE/TOEFL/OET/CELPIP/NAATI CCL - Kornerston';
  })

  return (
    <div>
      <TopNav />
      {/* <SimpleSlider />
      <Hero1 /> */}
      <CoursesFull />

      {/* <CoursesDetails /> */}
      {/* <About /> */}
      {/* <Contact /> */}
      <Footer />
    </div>
  );
}

export default CoursesAll;
