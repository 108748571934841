import SimpleSlider from "./components/Banner";
import Hero1 from "./components/Hero1";
import TopNav from "./components/NavBar/Nav";
import Footer from "./components/NavBar/Footer";
import Courses from "./components/Courses/courses";
import { useEffect } from "react";
import About from "./components/About";
import Contact from "./components/Contact/Contact";
import Event from "./components/Event";
function EventAll() {
  return (
    <div>
      <TopNav />
      {/* <SimpleSlider />
      <Hero1 />
      <Courses /> */}
      <Event />
      {/* <CoursesDetails /> */}
      {/* <About /> */}
      {/* <Contact /> */}
      <Footer />
    </div>
  );
}

export default EventAll;
