import SimpleSlider from "./components/Banner";
import Hero1 from "./components/Hero1";
import TopNav from "./components/NavBar/Nav";
import Footer from "./components/NavBar/Footer";
import Courses from "./components/Courses/courses";
import { useEffect } from "react";
import About from "./components/About";
import Contact from "./components/Contact/Contact";
import Blogs from "./components/Blogs/blogs";
import BlogsLinkApp from "./components/Blogs/BlogsLinkApp";
import BlogsLinkPte from "./components/Blogs/BlogsLinkPte";

var pathName = window.location.pathname;
console.log(pathName, "-------");

function BlogsAll() {
  useEffect(() => {
    if (pathName === "/blogs/blogs/iets") {
      document.title = "Digital Darwinism: Why Mastering";
    }
    if (pathName === "/blogs/blogs/pte") {
      document.title = "Digital Darwinism: Why Mastering";
    }
    if (pathName === "/blogs/blogs/oet") {
      document.title = "Digital Darwinism: Why Mastering";
    }
    if (pathName === "/blogs/blogs/celpip") {
      document.title = "Digital Darwinism: Why Mastering";
    }
    if (pathName === "/blogs/blogs/naaticcl") {
      document.title = "Digital Darwinism: Why Mastering";
    }
    if (pathName === "/blogs/blogs/toefl") {
      document.title = "Digital Darwinism: Why Mastering";
    }
  });

  return (
    <div>
      <TopNav />
      <Blogs />
      {pathName === "/blogs/iets" ? (
        <BlogsLinkApp />
      ) : pathName === "/blogs/pte" ? (
        <BlogsLinkPte />
      ) : pathName === "/blogs/toefl" ? (
        <BlogsLinkApp />
      ) : pathName === "/blogs/oet" ? (
        <BlogsLinkApp />
      ) : pathName === "/blogs/celpip" ? (
        <BlogsLinkApp />
      ) : pathName === "/blogs/naaticcl" ? (
        <BlogsLinkApp />
      ) : pathName === "/blogs/blogs" ? (
        <BlogsLinkApp />
      ) : (
        <div>Sorry</div>
      )}
      <Footer />
    </div>
  );
}

export default BlogsAll;
