import React, { Component } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Switch,
  Link,
} from "react-router-dom";
// import { store, persistor } from "./redux/store";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import "./style.css";
import CoursesDetailsAll from "./CoursesDetailsAll";
import AboutAll from "./AboutAll";
import ContactAll from "./ContactAll";
import CoursesAll from "./CoursesAll";
import OverSeasEducationAll from "./OverSeasEducationAll";
import GalleryAll from "./GalleryAll";
import EventAll from "./EventAll";
import BlogsAll from "./Blogs";
import BlogsLinkApp from "./components/Blogs/BlogsLinkApp";
import BlogsLinkPte from "./components/Blogs/BlogsLinkPte";
import BlogsLinkNaatiCcl from "./components/Blogs/BlogsLinkNaatiCcl";
import BlogsLinkDoulingo from "./components/Blogs/BlogsLinkDoulingo";
import BlogsLinkToefl from "./components/Blogs/BlogsLinkToefl";
import BlogsLinkOET from "./components/Blogs/BlogsLinkOET";

export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement("script");
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}

class Root extends Component {
  render() {
    return (
      <Router>
        <div>
          <div>
            {/* {AddLibrary("assets/js/vendor/modernizr-3.6.0.min.js")} */}
            {/* {AddLibrary("assets/js/vendor/jquery-3.6.0.min.js")} */}
            {/* {AddLibrary("assets/js/vendor/jquery-migrate-3.3.2.min.js")} */}
            {/* {AddLibrary("assets/js/popper.min.js")} */}
            {/* {AddLibrary("assets/js/bootstrap.min.js")} */}
            {/* {AddLibrary("assets/js/ajax-mail.js")} */}
            {/* {AddLibrary("assets/js/plugins.js")} */}
            {/* {AddLibrary("assets/js/main.js")} */}
            {AddLibrary("assets/js/main.js")}
          </div>
          <Switch>
            <Route exact path="/" component={App} />
            <Route
              exact
              path="/courses-details/:id"
              component={CoursesDetailsAll}
            />
            <Route
              exact
              path="/blogs/tips-for-success-IELTS-exams"
              // path="/blogs/iets"
              component={BlogsLinkApp}
            />
            <Route
              exact
              // path="/blogs/pte"
              path="/blogs/how-to-prepare-for-PTE-exams"
              component={BlogsLinkPte}
            />
            <Route
              exact
              // path="/blogs/pte"
              path="/blogs/all-you-need-to-know-about-NAATI-CCL"
              component={BlogsLinkNaatiCcl}
            />
            <Route
              exact
              path="/blogs/all-you-need-to-know-about-TOEFL"
              // path="/blogs/naatiCcl"
              component={BlogsLinkToefl}
            />
            <Route
              exact
              path="/blogs/duolingo-english-test"
              // path="/blogs/naatiCcl"
              component={BlogsLinkDoulingo}
            />
              <Route
              exact
              path="/blogs/occupational-english-test"
              // path="/blogs/naatiCcl"
              component={BlogsLinkOET}
            />
            
            <Route exact path="/about" component={AboutAll} />
            <Route exact path="/contact" component={ContactAll} />
            <Route exact path="/courses" component={CoursesAll} />
            <Route exact path="/blogs" component={BlogsAll} />
            <Route exact path="/overseas-education" component={OverSeasEducationAll} />
            <Route exact path="/gallery" component={GalleryAll} />
            <Route exact path="/features" component={EventAll} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default Root;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
