import React from "react";

const CoursesDetails3 = () => {
  return (
    <div classNameName="as-mainwrapper">
      <div classNameName="bg-white">
        <div className="breadcrumb-banner-area">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcrumb-text">
                  <h1 className="text-center">COURSES DETAILS</h1>
                  <div className="breadcrumb-bar">
                    <ul className="breadcrumb text-center">
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>COURSES DETAILS</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="course-details-area section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col--12">
                <div className="course-details-right-sidebar">
                  {/* <div className="events-details-img1 img-full">
                    <h3>PTE ACADEMIC</h3>
                    <img alt="" src="assets/img/details/1.jpg" />
                  </div> */}
                  <div className="single-item-content">
                  <div className="single-item-text1">
                      <h3>TOEFL</h3>
                    </div>
                    {/* <div className="single-item-comment-view">
                      <span>
                        <i className="zmdi zmdi-accounts"></i>88
                      </span>
                      <span>
                        <i className="zmdi zmdi-favorite"></i>43
                      </span>
                    </div> */}
                    {/* <div className="single-item-rating">
                      <i className="zmdi zmdi-star"></i>
                      <i className="zmdi zmdi-star"></i>
                      <i className="zmdi zmdi-star"></i>
                      <i className="zmdi zmdi-star"></i>
                      <i className="zmdi zmdi-star-half"></i>
                    </div> */}
                  </div>

                  <div className="about-lectures">
                    <h3 className="fw-bolder">Course Description</h3>
                    <p className="fw-semibold mt-4">
                      The TOEFL iBT® test measures your ability to use and
                      understand English at the university level. And it
                      evaluates how well you combine your reading, listening,
                      speaking, and writing skills to perform academic tasks.
                    </p>
                    <p className="fw-semibold mt-4">
                      The TOEFL iBT® test is given in English and administered
                      via the internet. It takes about 3 hours total for the 4
                      sections of the test (Reading, Listening, Speaking, and
                      Writing). Be sure to plan for 3½ hours, allowing 30
                      minutes for the check-in process.
                    </p>
                    <p className="fw-semibold mt-4">
                      Combining All 4 Skills: Reading, Listening, Speaking, and
                      Writing
                    </p>
                    <p className="fw-semibold mt-4">
                      During the test, you are asked to perform tasks that
                      combine your English communication skills, such as:
                    </p>
                    <ul>
                      <li>
                        1. Read, listen and then speak in response to a question
                      </li>
                      <li>
                        2. Listen and then speak in response to a question
                      </li>
                      <li>
                        3. Read, listen and then write in response to a question
                      </li>
                    </ul>
                    <p className="fw-semibold mt-4">
                      We at Kornerston train the students who are appearing for
                      ‘TOEFL’ in a comprehensive way by systematic approach with
                      enough authorised practice material and exercises that
                      instill confidence to take the exam.
                    </p>
                  </div>
                  <div className="about-area">
                    <div className="about-container text-center">
                  <a className="button-default button-large" href="/contact">
                  Join Us <i class="zmdi zmdi-chevron-right"></i>
                </a>
                </div>
              </div>
                  {/* <div className="about-lectures mt-5">
                    <h3 className="fw-bold">Why NAATI CCL?</h3>
                   
                    <h3 className="fw-bolder">
                      NAATI Credentialed Community Language Test is an exam which helps you increase your EOI score by 5 points for Australia PR application.
                    </h3>
                    <p className="fw-semibold mt-4">
                      As Tamil is a globally recognized language and spoken by millions, it is accepted as one for the LOTE (Language Other Than English); and thereby for the Credentialed Community Language (CCL) exam.
                    </p>
                    <br />
                    <h3 className="fw-bolder">
                      (We at Kornerston, train the students who are appearing for NAATI CCL in a comprehensive way by systematic approach with enough practice material and exercises that instill confidence to take the exam.)
                    </h3>
                    <br />
                    <h3 className="fw-bolder">
                      What is NAATI CCL?
                    </h3>
                    <p className="fw-semibold mt-4">
                      The NAATI CCL is an assessment of your language abilities at a community level. The Test determines your ability to translate and convey what is spoken by the speakers in a conversation – English & Language Other Than English (LOTE).
                    </p>
                    <p className="fw-semibold mt-4">
                      The CCL Test is generally taken by the applicants who are looking to lodge a point-based visa application. On successfully passing the test, applicants are awarded five bonus points. These bonus points are also referred to as Credentialed Community Language Points.
                    </p>
                    <br />
                    <h3 className="fw-bolder">
                      TEST FORMAT
                    </h3>
                    <p className="fw-semibold mt-4">
                      NAATI CCL tests are delivered ONLINE via Microsoft Teams.
                    </p>

                    <p className="fw-semibold mt-4">
                      The test consists of two dialogue recordings. Each dialogue represents a conversation between a native English Speaker and a native Language Other Than English (LOTE) speaker. Each dialogue is approximately 300 words, with about half in English and half in the LOTE. The dialogues are divided into segments of 35 words or less.
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursesDetails3;
