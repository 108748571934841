
import React, {useRef, useState} from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    backgroundColor: "blue",
    paddingBottom: 16,
    paddingRight: 16,
    marginTop: 16,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 500,
  },
  
});



const About = () => {
  const classes = useStyles();

  const slides =[
    {
      image: "/assets/img/testimonial/testimonial1.png",
      text: "Thank you so much for all your guidance and training. I have come to you after 6 attempts of PTE and unable to achieve my desired score of 79+ in all the categories. You have spent dedicated time with me mentoring and improving my English. I have observed one unique training method with Kornerston is you are not focusing on the score but you are focusing on improving the overall English. I dint find this trait in any other coaching centers. I want to personally thank you for all your prayers, wishes and treating me like your family member. I also want to highlight that the way to understand our strengths and weaknesses through screening test and framing a study plan specific to each students. With your support, I have achieved my desired score. Thank you so much for making my dream come true.",
      name: "Ajay Joseph Jesuraj ",
      position: "",
      place: "Sydney, Australia – PTE Score 85/90 – Skype Class (online class from Sydney)",
    },
    {
      image: "/assets/img/testimonial/testimonial2.jpg",
      text: "I want to thank Betty for helping me with my PTE test at a time when I had almost given up. I had lost confidence due to my earlier failed attempts at the test. Thanks to Betty, she worked on each area where I lacked perfection and taught simple and easy strategies to excel in them. She is a great motivator and she boosted my self confidence in a way that I scored really well. I highly recommend Kornerston Academy.",
      name: "Christina Christdas ",
      position: "",
      place: "Sydney, Australia – PTE Score 85/90 - Skype Class (online class from Bangalore)",
    },
    {
      image: "/assets/img/testimonial/testimonial2.png",
      text: "Kornerston Academy is the best place to join when you have the dream of scoring Superior level in English. My experience with Kornerston academy is amazing. Betty helped me to understand my weakness areas in English and gave valuable inputs to overcome my obstacles. Training materials, coaching, support, mock tests, boosting confidence level are essential to succeed in PTE and Kornerston Academy is the best place to get this. Thank you Kornerston for helping me and many others in scoring 8 Bands in PTE.",
      name: "Deephan Mohanraj",
      position: "",
      place: "PTE Score 84/90",
    },
    {
      image: "/assets/img/testimonial/testimonial3.png",
      text: "Thanks to Kornerston Academy and Betty Mam for helping me achieve this herculean task of getting 79+ in PTE. Initially, I had no idea about the exam pattern nor about the tips and tricks to crack this exam. But attended classes every day, got the required coaching, tips, tricks, homework and the mock tests to get familiar with the pattern. Gave my first attempt and scored 70’s in all sections. Later joined couple of Whats App groups and lost the track, started getting inputs from many and failed to achieve the target in my 2nd and 3rd attempt, also lost the hope and decided to quit. But wanted to try one last time and I did seek help from Mam again for my 4th attempt and sent my recordings for review. In return I did not get the review, instead I got a call from Mam, asking me to come back to class the day before the exam. I just did what Mam had told me to do and aced PTE.",
      name: "Hemanathan Rajendran",
      position: "",
      place: "Sydney, Australia – PTE Score 87/90",
    },
    {
      image: "/assets/img/testimonial/testimonial5.png",
      text: "",
      name: "",
      position: "",
      place: "",
    },
    {
      image: "/assets/img/testimonial/testimonial6.jpg",
      text: "It was a great experience to have received the coaching for PTE exams from Kornerston academy Mrs. Betty provided all the needed guidance and support which helped me to achieve a high score in PTE. She is an excellent tutor who tries to get the best out of each student. The team is highly knowledgeable and are very friendly. Every student is given equal importance and individual attention. The streamlining of various tasks was beneficent to take up the test in a more confident manner. The one on one interaction helped me in developing my speaking skills. The study material and the series of practice tests were really fruitful. I highly recommend Kornerston academy for PTE coaching.",
      name: "Kezia Pravinth ",
      position: "",
      place: "PTE Score 86/90",
    },
    {
      image: "/assets/img/testimonial/testimonial7.jpg",
      text: "I would recommend Pearson Test of English Academic for anyone planning to take English proficiency test as it is very practical, eliminates any chance of biased scoring and more convenient in choosing exam dates. Results are quick and fully computerized. And best place to get coached is Kornerston Academy which enabled me to get the desired results.",
      name: "Logesh Kumar",
      position: "",
      place: "Melbourne, Australia - PTE Score 81/90",
    },
    {
      image: "/assets/img/testimonial/testimonial8.jpg",
      text: "If you are in search of a companion to take you to success, just go for Kornerston Academy. When I began my hunt for a PTE coaching centre at Chennai, I just relied on Google reviews and finally went in for Kornerston Academy. I’m happy I did! You won’t believe the amount of practice and guidance Betty maam gives you and all that she tells you is to book your exam dates, and let’s begin... Just take her words!! I could spend only a few weekend sessions with her, but I am absolutely satisfied and happy that, I found the right place and the right person! She’s someone who is always approachable and so dedicated! Thank you Maam!",
      name: "Milly Mary Chacko",
      position: "",
      place: "Melbourne, Australia – PTE Score 84/90",
    },
    {
      image: "/assets/img/testimonial/testimonial9.jpg",
      text: "It was a wonderful experience studying at Kornerston! Kornerston is the ideal place for learning PTE/IELTS and an excellent coaching center. Best teaching strategies and methods are followed and the center provides a positive classroom atmosphere for studying! Betty Mam is an amazing teacher, mentor and a wonderful human being. She assesses, understands and connects with students individually and brings out the best. She has a great teaching style and goes the extra mile to explain and give detailed feedback and helped me overcome challenging tasks in PTE (especially in Speaking). She gives continuous feedback, tremendous support and motivation. Her expertise in PTE and in-depth knowledge makes the learning process easier. Learning materials, mock tests and practice exercises were very useful and practicing regularly helped me to achieve Superior score in a very short period of time. With hard work, perseverance and an exemplary mentor like Betty Mam it is easy to succeed! Thank you so much for being a part of my success Mam!",
      name: "Prathipa Gupta",
      position: "",
      place: "PTE Score 84/90",
    },
    {
      image: "/assets/img/testimonial/testimonial10.jpg",
      text: "I had joined Kornerston to prepare for my PTE exam, to support my migration application to Australia. I was trained by Betty maam and her team of trainers. If you are planning to sit for PTE or IELTS - this is the academy you want to go to. The academy provides an excellent atmosphere for learning. Betty maam takes indepth personal interest in preparing you for the exam. The coaching and tips provided instills confidence and prepares you to excel. I was able to crack the exam solely because of her guidance and support. :) I would very strongly recommend all potential test takers to join this fantastic academy.",
      name: "Pratim ",
      position: "",
      place: "United Kingdom - PTE Score 90/90",
    },
    {
      image: "/assets/img/testimonial/testimonial11.jpg",
      text: "Firstly I would thank you for helping me achieve 79+ in PTE and make me PR process easier…I still remember the first two essays which I wrote and totally out of topic :p...The reading time clocks out when I was long way before completion…Thanks for all the guidance and motivation Betty mam...",
      name: "Prashanth ",
      position: "",
      place: "Melbourne, Australia – PTE Score 82/90",
    },
    {
      image: "/assets/img/testimonial/testimonial12.jpg",
      text: "I would like to first off all thank Mrs. Betty for her guidance and support in getting my dream score of 79+ in PTE. I have been trying to score 79+ for almost 2 years and couldn't manage to achieve it even after multiple times. I got to know about the Kornerston academy from one of my friend who is a Kornerston academy student. Until I witnessed her way of teaching I was skeptical as I have already attended one PTE training academy. Once she started the course I got to know that this time around I would be cracking it and I just did it. The most important thing in the academy is that Mrs. Betty assess your skill first then she will get to know our strengths and weakness. After the end of the course you would no longer have any weak areas as such. She would have fixed your mistakes by then. I strongly recommend people who wants high score in PTE to get trained in Kornerston academy as they find your mistakes and fix them. Thanks a ton to Mrs.Betty. Keep helping people. God bless!!!!!",
      name: "Kiran Enigo",
      position: "",
      place: "",
    },
    {
      image: "/assets/img/testimonial/testimonial13.jpg",
      text: "After repeated failures and running around in circles, I finally landed in Kornerston. Full credits to Betty Mam and Jonathan in helping me clear the exam with 79+ in individual skills and an overall score of 86. Their approach was systematic and professional after all PTE is about winning over a computer.  Like everyone else, I tried exams on my own by self-learning using youtube materials. But, I Constantly lagged in writing skills. Kornerston's motto is, Everyone is different hence individual attention and performances are closely monitored. At Kornerston, I took a fast track class and completely forgot my previous methods. Betty Mam's support, guidance, constant motivation, and prayers gave me confidence after being completely broke.",
      name: "Sriram ",
      position: "",
      place: "Brisbane, Australia – PTE Score 86/90",
    },
    {
      image: "/assets/img/testimonial/testimonial14.jpg",
      text: "Whilst feeling doomed after number of attempts, last knock was onto Kornerston Academy and thier approach had been amicable and there were grounds of optimism. Mrs. Betty, Oh Mam! You being X factor, removing you from my life equation, I would never have achieved it without you. Took off in 2017 after cracking PTE and still flying in colours in here in Ireland. Anyone reading this, don't think twice before knocking their doors. Cheers!",
      name: "Sajin ",
      position: "",
      place: "Galway, Ireland",
    },
    {
      image: "/assets/img/testimonial/testimonial15.jpg",
      text: "Kornerston academy is tutored by Betty Jerusha. She gives you a sample test to know where you stand and from then on, it is a mind blowing experience where you will learn what you need without even you knowing it. All you have to do, is simply listen to her and do as you are told and you will know you are getting confident day by day. Betty, is dedicated and sincere and always shows interest for your betterment. Best place IELTS/PTE",
      name: "Daniel Barnabas",
      position: "",
      place: "Ireland – Dublin – PTE Score 83/90",
    },
    {
      image: "/assets/img/testimonial/testimonial16.jpg",
      text: "I am Ravikrishnan an Agile Delivery Manager working with an Australian based insurance company based out of Melbourne. I am privileged and delighted to express my gratitude to Kornerston academy who helps me in realising my PR aspiration for Australia. I would strongly recommend Kornerston academy to take up PTE training and it's only because of Betty, who is a consummate professional with profound experience in PTE coaching. She works individually with the candidates and meets the need of each candidate she works with and her coaching is very comprehensive and detailed in nature. If you really want to crack PTE with outstanding scores, then Kornerston is the gateway for realizing your big dreams.",
      name: "Ravikrishnan ",
      position: "",
      place: "Melbourne, Australia",
    },
    {
      image: "/assets/img/testimonial/testimonial17.jpg",
      text: "I did my PTE coaching at Kornerston Academy a couple of years ago. I was a bit nervous and anxious about the PTE exam pattern which is entirely different from our academic exams. But their exam focused classes, reference materials, e-books, mock tests etc, helped me and many other students to achieve the desired result.Betty mam is excellent in assessing one’s language skills and providing tips and techniques to improve their English. You can expect an individual attention from them which is very important while preparing exams like PTE and IELTS. Their coaching also helped me to improve my confidence level when I address a larger audience.",
      name: "Ragesh ",
      position: "",
      place: "Sydney Australia",
    },

  ]
  
  
  return (


    // {slides.map((testimonial, index) => (
    //   <div className="single-testimonial-area col-12" key={index}>
    //     <div className="testimonial-image">
    //       <img
    //         src={testimonial.image}
    //         alt={testimonial.imageAlt}
    //         width="104"
    //         height="104"
    //       />
    //     </div>
    //     <div className="testimonial-content">
    //       <p className="author-desc">{testimonial.text}</p>
    //       <h4 className="testimonial-author">{testimonial.name}</h4>
    //       <h5 className="testimonial-author">{testimonial.place}</h5>
    //     </div>
    //   </div>
    // ))}
    <>
    <div className="as-mainwrapper">
      <div className="bg-white">
      <div className="container testimonials-header">
          <h3>TESTIMONIES</h3>
        </div>
        <div className="slider-area">
          <div className="hero-slider owl-carousel" id="testinomial" data-autoplay="true" data-loop="true">
            <div className="single-testimonial-area col-12">
              <div className="testimonial-image">
                <img
                  src="assets/img/testimonial/testimonial1.png"
                  alt=""
                  width="104"
                  height="104"
                />
              </div>
              <div className="testimonial-content">
                <p className="author-desc">
                  Thank you so much for all your guidance and training. I
                  have come to you after 6 attempts of PTE and unable to
                  achieve my desired score of 79+ in all the categories.
                  You have spent dedicated time with me mentoring and
                  improving my English. I have observed one unique
                  training method with Kornerston is you are not focusing
                  on the score but you are focusing on improving the
                  overall English. I dint find this trait in any other
                  coaching centers. I want to personally thank you for all
                  your prayers, wishes and treating me like your family
                  member. I also want to highlight that the way to
                  understand our strengths and weaknesses through
                  screening test and framing a study plan specific to each
                  students. With your support, I have achieved my desired
                  score. Thank you so much for making my dream come true.
                </p>
                <h4 className="testimonial-author">Ajay Joseph Jesuraj</h4>
                <h5 className="testimonial-author">
                  Sydney, Australia – PTE Score 85/90 – Skype Class
                  (online class from Sydney)
                </h5>
              </div>
            </div>
          {/* </div> */}

          {/* <div className="col-12"> */}
            <div className="single-testimonial-area col-12">
              <div className="testimonial-image">
                <img
                  src="assets/img/testimonial/testimonial2.jpg"
                  alt=""
                  width="104"
                  height="104"
                />
              </div>
              <div className="testimonial-content">
                <p className="author-desc">
                  I did my PTE coaching at Kornerston Academy a couple of years ago. I was a bit nervous and anxious about the PTE exam pattern which is entirely different from our academic exams. But their exam focused classes, reference materials, e-books, mock tests etc, helped me and many other students to achieve the desired result. Betty mam is excellent in assessing one’s language skills and providing tips and techniques to improve their English. You can expect an individual attention from them which is very important while preparing exams like PTE and IELTS. Their coaching also helped me to improve my confidence level when I address a larger audience. I would highly recommend Kornerston Academy to those who are eagerly looking for an excellent and result oriented coaching center.
                </p>
                <h4 className="testimonial-author">Ragesh</h4>
                <h5 className="testimonial-author">
                Sydney, Australia
                </h5>
              </div>
            </div>
          {/* </div> */}
          {/* <div className="col-12"> */}
                  <div className="single-testimonial-area col-12">
                    <div className="testimonial-image">
                      <img
                        src="assets/img/testimonial/testimonial3.png"
                        alt=""
                        width="104"
                        height="104"
                      />
                    </div>
                    <div className="testimonial-content">
                      <p className="author-desc">
                        Whilst feeling doomed after number of attempts, last
                        knock was onto Kornerston Academy and thier approach had
                        been amicable and there were grounds of optimism. Mrs.
                        Betty, Oh Mam! You being X factor, removing you from my
                        life equation, I would never have achieved it without
                        you. Took off in 2017 after cracking PTE and still
                        flying in colours in here in Ireland. Anyone reading
                        this, don't think twice before knocking their doors.
                        Cheers!
                      </p>
                      <h4 className="testimonial-author">Sajin</h4>
                      <h5 className="testimonial-author">Galway, Ireland</h5>
                    </div>
                  </div>
            {/* </div> */}
            
          {/* <div className="col-12"> */}
            <div className="single-testimonial-area col-12">
              <div className="testimonial-image">
                <img
                  src="assets/img/testimonial/testimonial4.png"
                  alt=""
                  width="104"
                  height="104"
                />
              </div>
              <div className="testimonial-content">
                <p className="author-desc">
                Sydney, Australia – PTE Score 85/90 - Skype Class (online class from Bangalore)
I want to thank Betty for helping me with my PTE test at a time when I had almost given up. I had lost confidence due to my earlier failed attempts at the test. Thanks to Betty, she worked on each area where I lacked perfection and taught simple and easy strategies to excel in them. She is a great motivator and she boosted my self confidence in a way that I scored really well. I highly recommend Kornerston Academy.

                </p>
                <h4 className="testimonial-author">Christina Christdas</h4>
                <h5 className="testimonial-author">
                Sydney, Australia – PTE Score 85/90- Skype Class (online class from Bangalore)
                </h5>
              </div>
            </div>

            
            <div className="single-testimonial-area col-12">
              <div className="testimonial-image">
                <img
                  src="assets/img/testimonial/testimonial5.png"
                  alt=""
                  width="104"
                  height="104"
                />
              </div>
              <div className="testimonial-content">
                <p className="author-desc">
                I had joined Kornerston to prepare for my PTE exam, to support my migration application to Australia. I was trained by Betty maam and her team of trainers. If you are planning to sit for PTE or IELTS - this is the academy you want to go to. The academy provides an excellent atmosphere for learning. Betty maam takes indepth personal interest in preparing you for the exam. The coaching and tips provided instills confidence and prepares you to excel. I was able to crack the exam solely because of her guidance and support. :) I would very strongly recommend all potential test takers to join this fantastic academy.
                </p>
                <h4 className="testimonial-author">Pratim </h4>
                <h5 className="testimonial-author">
                United Kingdom - PTE Score 90/90
                </h5>
              </div>
            </div>

            <div className="single-testimonial-area col-12">
              <div className="testimonial-image">
                <img
                  src="assets/img/testimonial/testimonial6.jpg"
                  alt=""
                  width="104"
                  height="104"
                />
              </div>
              <div className="testimonial-content">
                <p className="author-desc">
                Firstly I would thank you for helping me achieve 79+ in PTE and make me PR process easier…I still remember the first two essays which I wrote and totally out of topic :p...The reading time clocks out when I was long way before completion…Thanks for all the guidance and motivation Betty mam...
                </p>
                <h4 className="testimonial-author">Prashanth</h4>
                <h5 className="testimonial-author">
                Melbourne, Australia – PTE Score 82/90
                </h5>
              </div>
            </div>

            <div className="single-testimonial-area col-12">
              <div className="testimonial-image">
                <img
                  src="assets/img/testimonial/testimonial7.jpg"
                  alt=""
                  width="104"
                  height="104"
                />
              </div>
              <div className="testimonial-content">
                <p className="author-desc">
                After repeated failures and running around in circles, I finally landed in Kornerston. Full credits to Betty Mam and Jonathan in helping me clear the exam with 79+ in individual skills and an overall score of 86. Their approach was systematic and professional after all PTE is about winning over a computer.  Like everyone else, I tried exams on my own by self-learning using youtube materials. But, I Constantly lagged in writing skills. Kornerston's motto is, "Everyone is different" hence individual attention and performances are closely monitored. At Kornerston, I took a fast track class and completely forgot my previous methods. Betty Mam's support, guidance, constant motivation, and prayers gave me confidence after being completely broke.
                </p>
                <h4 className="testimonial-author">Sriram</h4>
                <h5 className="testimonial-author">
                Brisbane, Australia – PTE Score 86/90
                </h5>
              </div>
            </div>

            <div className="single-testimonial-area col-12">
              <div className="testimonial-image">
                <img
                  src="assets/img/testimonial/testimonial8.jpg"
                  alt=""
                  width="104"
                  height="104"
                />
              </div>
              <div className="testimonial-content">
                <p className="author-desc">
                Kornerston academy is tutored by Betty Jerusha. She gives you a sample test to know where you stand and from then on, it is a mind blowing experience where you will learn what you need without even you knowing it. All you have to do, is simply listen to her and do as you are told and you will know you are getting confident day by day. Betty, is dedicated and sincere and always shows interest for your betterment. Best place IELTS/PTE
                </p>
                <h4 className="testimonial-author">Daniel Barnabas</h4>
                <h5 className="testimonial-author">
                Ireland – Dublin – PTE Score 83/90
                </h5>
              </div>
            </div>

            <div className="single-testimonial-area col-12">
              <div className="testimonial-image">
                <img
                  src="assets/img/testimonial/testimonial15.jpg"
                  alt=""
                  width="104"
                  height="104"
                />
              </div>
              <div className="testimonial-content">
                <p className="author-desc">
                Thanks to Kornerston Academy and Betty Mam for helping me achieve this herculean task of getting 79+ in PTE. Initially, I had no idea about the exam pattern nor about the tips and tricks to crack this exam. But attended classes every day, got the required coaching, tips, tricks, homework and the mock tests to get familiar with the pattern. Gave my first attempt and scored 70’s in all sections. Later joined couple of Whats App groups and lost the track, started getting inputs from many and failed to achieve the target in my 2nd and 3rd attempt, also lost the hope and decided to quit. But wanted to try one last time and I did seek help from Mam again for my 4th attempt and sent my recordings for review. In return I did not get the review, instead I got a call from Mam, asking me to come back to class the day before the exam. I just did what Mam had told me to do and aced PTE.
                </p>
                <h4 className="testimonial-author">Hemanathan Rajendran </h4>
                <h5 className="testimonial-author">
                Sydney, Australia – PTE Score 87/90
                </h5>
              </div>
            </div>

            <div className="single-testimonial-area col-12">
              <div className="testimonial-image">
                <img
                  src="assets/img/testimonial/testimonial10.jpg"
                  alt=""
                  width="104"
                  height="104"
                />
              </div>
              <div className="testimonial-content">
                <p className="author-desc">
                I am Ravikrishnan an Agile Delivery Manager working with an Australian based insurance company based out of Melbourne. I am privileged and delighted to express my gratitude to Kornerston academy who helps me in realising my PR aspiration for Australia. I would strongly recommend Kornerston academy to take up PTE training and it's only because of Betty, who is a consummate professional with profound experience in PTE coaching. She works individually with the candidates and meets the need of each candidate she works with and her coaching is very comprehensive and detailed in nature. If you really want to crack PTE with outstanding scores, then Kornerston is the gateway for realizing your big dreams.
                </p>
                <h4 className="testimonial-author">Ravikrishnan</h4>
                <h5 className="testimonial-author">
                Melbourne, Australia – PTE Score 83/90
                </h5>
              </div>
            </div>

            <div className="single-testimonial-area col-12">
              <div className="testimonial-image">
                <img
                  src="assets/img/testimonial/testimonial16.jpg"
                  alt=""
                  width="104"
                  height="104"
                />
              </div>
              <div className="testimonial-content">
                <p className="author-desc">
                If you are in search of a companion to take you to success, just go for Kornerston Academy. When I began my hunt for a PTE coaching centre at Chennai, I just relied on Google reviews and finally went in for Kornerston Academy. I’m happy I did! You won’t believe the amount of practice and guidance Betty maam gives you and all that she tells you is to book your exam dates, and let’s begin... Just take her words!! I could spend only a few weekend sessions with her, but I am absolutely satisfied and happy that, I found the right place and the right person! She’s someone who is always approachable and so dedicated! Thank you Maam
                </p>
                <h4 className="testimonial-author">Milly Mary Chacko </h4>
                <h5 className="testimonial-author">
                – Melbourne, Australia – PTE Score 84/90
                </h5>
              </div>
            </div>

            <div className="single-testimonial-area col-12">
              <div className="testimonial-image">
                <img
                  src="assets/img/testimonial/testimonial11.jpg"
                  alt=""
                  width="104"
                  height="104"
                />
              </div>
              <div className="testimonial-content">
                <p className="author-desc">
                Kornerston Academy is the best place to join when you have the dream of scoring "Superior" level in English. My experience with Kornerston academy is amazing. Betty helped me to understand my weakness areas in English and gave valuable inputs to overcome my obstacles. Training materials, coaching, support, mock tests, boosting confidence level are essential to succeed in PTE and Kornerston Academy is the best place to get this. Thank you Kornerston for helping me and many others in scoring 8 Bands in PTE.
                </p>
                <h4 className="testimonial-author">Deephan Mohanraj</h4>
                <h5 className="testimonial-author">
                Ireland – Dublin – PTE Score 84/90
                </h5>
              </div>
            </div>

            <div className="single-testimonial-area col-12">
              <div className="testimonial-image">
                <img
                  src="assets/img/testimonial/testimonial12.jpg"
                  alt=""
                  width="104"
                  height="104"
                />
              </div>
              <div className="testimonial-content">
                <p className="author-desc">
                It was a great experience to have received the coaching for PTE exams from Kornerston academy Mrs. Betty provided all the needed guidance and support which helped me to achieve a high score in PTE. She is an excellent tutor who tries to get the best out of each student. The team is highly knowledgeable and are very friendly. Every student is given equal importance and individual attention. The streamlining of various tasks was beneficent to take up the test in a more confident manner. The one on one interaction helped me in developing my speaking skills. The study material and the series of practice tests were really fruitful. I highly recommend Kornerston academy for PTE coaching.
                </p>
                <h4 className="testimonial-author">Kezia Pravinth</h4>
                <h5 className="testimonial-author">
                Ireland – Dublin – PTE Score 86/90
                </h5>
              </div>
            </div>


            <div className="single-testimonial-area col-12">
              <div className="testimonial-image">
                <img
                  src="assets/img/testimonial/testimonial9.jpg"
                  alt=""
                  width="104"
                  height="104"
                />
              </div>
              <div className="testimonial-content">
                <p className="author-desc">
                I would recommend Pearson Test of English Academic for anyone planning to take English proficiency test as it is very practical, eliminates any chance of biased scoring and more convenient in choosing exam dates. Results are quick and fully computerized. And best place to get coached is Kornerston Academy which enabled me to get the desired results.
                </p>
                <h4 className="testimonial-author">Logesh Kumar</h4>
                <h5 className="testimonial-author">
                Melbourne, Australia - PTE Score 81/90
                </h5>
              </div>
            </div>

            <div className="single-testimonial-area col-12">
              <div className="testimonial-image">
                <img
                  src="assets/img/testimonial/testimonial13.jpg"
                  alt=""
                  width="104"
                  height="104"
                />
              </div>
              <div className="testimonial-content">
                <p className="author-desc">
                I would like to first off all thank Mrs. Betty for her guidance and support in getting my dream score of 79+ in PTE. I have been trying to score 79+ for almost 2 years and couldn't manage to achieve it even after multiple times. I got to know about the Kornerston academy from one of my friend who is a Kornerston academy student. Until I witnessed her way of teaching I was skeptical as I have already attended one PTE training academy. Once she started the course I got to know that this time around I would be cracking it and I just did it. The most important thing in the academy is that Mrs. Betty assess your skill first then she will get to know our strengths and weakness. After the end of the course you would no longer have any weak areas as such. She would have fixed your mistakes by then. I strongly recommend people who wants high score in PTE to get trained in Kornerston academy as they find your mistakes and fix them. Thanks a ton to Mrs.Betty. Keep helping people. God bless!!!!!
                </p>
                <h4 className="testimonial-author">Kiran Enigo</h4>
                <h5 className="testimonial-author">
                Melbourne, Australia - PTE Score 81/90
                </h5>
              </div>
            </div>


            <div className="single-testimonial-area col-12">
              <div className="testimonial-image">
                <img
                  src="assets/img/testimonial/testimonial14.jpg"
                  alt=""
                  width="104"
                  height="104"
                />
              </div>
              <div className="testimonial-content">
                <p className="author-desc">
                It was a wonderful experience studying at Kornerston! Kornerston is the ideal place for learning PTE/IELTS and an excellent coaching center. Best teaching strategies and methods are followed and the center provides a positive classroom atmosphere for studying!
                </p>
                <h4 className="testimonial-author">Prathipa Gupta </h4>
                <h5 className="testimonial-author">
                Ireland – Dublin – PTE Score 84/90
                </h5>
              </div>
            </div>


            <div className="single-testimonial-area col-12">
              <div className="testimonial-image">
                <img
                  src="assets/img/testimonial/testimonial8.jpg"
                  alt=""
                  width="104"
                  height="104"
                />
              </div>
              <div className="testimonial-content">
                <p className="author-desc">
                Kornerston academy is tutored by Betty Jerusha. She gives you a sample test to know where you stand and from then on, it is a mind blowing experience where you will learn what you need without even you knowing it. All you have to do, is simply listen to her and do as you are told and you will know you are getting confident day by day. Betty, is dedicated and sincere and always shows interest for your betterment. Best place IELTS/PTE
                </p>
                <h4 className="testimonial-author">Daniel Barnabas</h4>
                <h5 className="testimonial-author">
                Ireland – Dublin – PTE Score 83/90
                </h5>
              </div>
            </div>

            
          </div>
          </div>
        {/* </div> */}

        <div class="col-md-12 col-sm-12 mb-80 text-center">
        <a href="https://www.google.com/search?q=kornerston+academy+review&amp;oq=kornerston+bascademy&amp;aqs=chrome.1.69i57j0l5.14832j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x3a525ebc934883d9:0xb9206341ece65c7b,1,,," class="button-default button-large">More Testimonies <i class="zmdi zmdi-chevron-right"></i></a>
          </div>
      </div>
    </div>
    </>
  );
};

export default About;
