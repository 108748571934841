import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
const useStyles = makeStyles({
  root: {
    backgroundColor: "blue",
    paddingBottom: 16,
    paddingRight: 16,
    marginTop: 16,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 500,
  },
  aboutContainer: {
    marginTop: "60px",
  },
});
const AuthorisedCenter = () => {
  const classes = useStyles();
  return (
    <div>
      <div className="about-authorised mt-95">
        <div className="container">
          <div className="row">
            <div className="">
              <div className="about-container">
                <h3>
                AUTHORISED TEST REGISTRATION CENTRES
                </h3>
              </div>
            </div>
            {/* <div className="col-lg-5">
              <div className="about-image-area img-full">
                <img src="assets/img/about/about1.jpg" alt="" />
              </div>
            </div> */}
          </div>
          <div className="row about-authurised-img">
          {/* <img src="assets/img/authorised/british.png" alt="" /> */}
          <img src="assets/img/authorised/idp.png" alt="" />
          <img src="assets/img/authorised/ets.png" alt="" />
          <img src="assets/img/authorised/pte.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthorisedCenter;
