import SimpleSlider from "./components/Banner";
import Hero1 from "./components/Hero1";
import TopNav from "./components/NavBar/Nav";
import Footer from "./components/NavBar/Footer";
import Courses from "./components/Courses/courses";
import { useEffect } from "react";
import CoursesDetails1 from "./components/Courses/CoursesDetails1";
import CoursesDetails2 from "./components/Courses/CoursesDetails2";
import CoursesDetails3 from "./components/Courses/CoursesDetails3";
import CoursesDetails4 from "./components/Courses/CoursesDetails4";
import CoursesDetails5 from "./components/Courses/CoursesDetails5";
import CoursesDetails6 from "./components/Courses/CoursesDetails6";
import BlogsLinkApp from "./components/Courses/BlogsLinkApp";
import BlogsLinkPte from "./components/Blogs/BlogsLinkPte";

var pathName = window.location.pathname;
console.log(pathName, "-------");

function CoursesDetailsAll() {

  useEffect(()=>{

    if(pathName === "/courses-details/pte"){
      document.title = 'Best PTE Coaching | PTE Training | PTE Course Chennai';
    }

    if(pathName === "/courses-details/ielts"){
      document.title = 'IELTS Coaching | IELTS Training Center | Study IELTS Online';
    }
    if(pathName === "/courses-details/toefl"){
      document.title = 'Best TOEFL Online Coaching | TOEFL Training Institute Chennai';
    }
    if(pathName === "/courses-details/oet"){
      document.title = 'Best OET Coaching Center | OET Training Online | Kornerston';
    }
    if(pathName === "/courses-details/celpip"){
      document.title = 'Best CELPIP Coaching Classes | CELPIP Exam Preparation';
    }
    if(pathName === "/courses-details/naati-ccl"){
      document.title = 'NAATI CCL Tamil | NAATI Tamil Preparation | NAATI Tamil';
    }   
  })

  return (
    <div>
      <TopNav />
      {pathName === "/courses-details/pte" ? (
        <CoursesDetails1 />
      ) : pathName === "/courses-details/ielts" ? (
        <CoursesDetails2 />
      ) : pathName === "/courses-details/toefl" ? (
        <CoursesDetails3 />
      ) : pathName === "/courses-details/oet" ? (
        <CoursesDetails4 />
      ) : pathName === "/courses-details/celpip" ? (
        <CoursesDetails5 />
      ) : pathName === "/courses-details/naati-ccl" ? (
          <CoursesDetails6 />
      
      ) :
      (
        <div>Sorry</div>
      )}

      <Footer />
    </div>
  );
}

export default CoursesDetailsAll;
