import React,{useEffect, useState} from "react";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightLong } from "@fortawesome/free-solid-svg-icons";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { DialogActions, DialogContent, Typography } from "@mui/material";
const useStyles = makeStyles({
  root: {
    backgroundColor: "blue",
    paddingBottom: 16,
    paddingRight: 16,
    marginTop: 16,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 500,
  },
  hero: {
    position: "absolute",
    top: 0,
    transform: "translateY(50%)",
    left: "auto",
    marginLeft: "370px",
    maxWidth: 570,
    color: "white",
  },
});

// const PrevArrow = (props) => {
//   const classes = useStyles();
//   const { className, style, onClick } = props;
//   return (
//     <div className="owl-nav">
//       <button
//         type="button"
//         role="presentation"
//         onClick={onClick}
//         className="owl-prev"
//       >
//         <FontAwesomeIcon icon={faLeftLong} />
//       </button>
//     </div>
//   );
// };
// const NextArrow = (props) => {
//   const classes = useStyles();
//   const { className, style, onClick } = props;
//   return (
//     <div className="owl-nav">
//       <button
//         type="button"
//         role="presentation"
//         onClick={onClick}
//         className="owl-next"
//       >
//         <FontAwesomeIcon icon={faRightLong} />
//       </button>
//     </div>
//   );
// };
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SimpleSlider = () => {
  const classes = useStyles();

  const [showDialog, setShowDialog] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('md');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowDialog(true);
      handleClickOpen(); // Open the dialog automatically after 1 second
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
   
    <div className="as-mainwrapper">
      <div className="bg-white">

      {/* <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <CloseIcon
          onClick={handleClose}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            cursor: 'pointer',
          }}
        />
       <div className="Offer-popup">
        <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/study-canada/study-permit/student-direct-stream/eligibility.html">
          <img src="assets/img/banner/slide.jpeg" alt="Document" style={{ maxWidth: '100%', height: 'auto' }} />
        </a>
        </div>
        
        </Dialog> */}

        {showDialog && (
          <div className="dialog">
             {/* <a href="https://www.canada.ca/en/immigration-refugeescitizenship/services/study-canada/study-permit/student-direct-stream/eligibility.html">
          </a> */}
          </div>
        )}

        <div className="slider-area">
          <div 
          className="hero-slider owl-carousel"
       >
          <div
  className="single-slider" 
  style={{
    backgroundImage: "url(assets/img/banner/banner1.png)",
  
    //backgroundSize: "100% auto",// Adjust background-size property
    // width: "100%",           // Set width to 100%
    // height: "550%"          // Set height to your desired value
  }}
>
              <div className="hero-slider-content mt-10">
                <h1>
                  Attain Perfection With <br /> Enough Preparation
                </h1>
                <p>
                  “Our sole objective is to attain perfection with enough
                  preparation.” <br />
                  This has made Kornerston Academy to evolve into a
                  multifaceted, dynamic and result oriented coaching
                  institution, catering to a variety of students requiring
                  guidance and training to attain proficiency in the English
                  language.
                </p>
                <div className="slider-btn">
                  <a className="button-default" href="/courses">
                    View Courses
                  </a>
                </div>
              </div>
            </div>

            <div
              className="single-slider"
              style={{ backgroundImage: "url(assets/img/banner/banner2.png)" }}
            >
              <div className="hero-slider-content">
                <h1>Flexible Schedule</h1>
                <p>
                  “We provides various courses of different time duration for
                  your benefit. For college students & working individuals, we
                  offer flexible timings so that they can pursue the course
                  along with their other work. Visit our Center to know about
                  class timings.”
                </p>
                <div className="slider-btn">
                  <a className="button-default" href="/courses">
                    View Courses
                  </a>
                </div>
              </div>
            </div>

            <div
              className="single-slider"
              style={{ backgroundImage: "url(assets/img/banner/banner3.png)" }}
            >
              <div className="hero-slider-content">
                <h1>One On One Coaching</h1>
                <p>
                  “One on one coaching and assessment; Interactive and friendly
                  environment. We are preparing our students with enough
                  individual attention to aid them overcome specific
                  difficulties without sweat.”
                </p>
                <div className="slider-btn">
                  <a className="button-default" href="/courses">
                    View Courses
                  </a>
                </div>
              </div>
            </div>

            <div
              className="single-slider"
              style={{ backgroundImage: "url(assets/img/banner/banner4.png)" }}
            >
              <div className="hero-slider-content">
                <h1>Modern Learning Facilities</h1>
                <p>
                  “Our class time includes an active learning and creating piece
                  that will yield student engagement. All our classrooms are
                  equipped with multiple computers, ensuring that all students
                  have access to the assigned resources no matter the class
                  size.”
                </p>
                <div className="slider-btn">
                  <a className="button-default" href="/courses">
                    View Courses
                  </a>
                </div>
              </div>
            </div>

            <div
              className="single-slider"
              style={{ backgroundImage: "url(assets/img/banner/banner1.png)" ,
             }}
            >
              <div className="hero-slider-content">
                <h1>Flexible Schedule</h1>
                <p>
                  “We provides various courses of different time duration for
                  your benefit. For college students & working individuals, we
                  offer flexible timings so that they can pursue the course
                  along with their other work. Visit our Center to know about
                  class timings.”
                </p>
                <div className="slider-btn">
                  <a className="button-default" href="/courses">
                    View Courses
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleSlider;
