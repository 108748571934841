import { useEffect } from "react";
import SimpleSlider from "./components/Banner";
import Hero1 from "./components/Hero1";
import TopNav from "./components/NavBar/Nav";
import Footer from "./components/NavBar/Footer";
import Courses from "./components/Courses/courses";
import Testimonial from "./components/Testimonial";
import AuthorisedCenter from "./AuthorisedCenter";
import VideoPlay from "./components/VedioPlay";
import About from "./components/About";
import Contact from "./components/Contact/Contact";

function App() {
  useEffect(()=>{
    document.title = 'Certified Coaching Center - Kornerston Academy';
  })
  return (
    <div>
      <TopNav />
      <SimpleSlider />
      <AuthorisedCenter />
      <Hero1 />
      <Courses />
      <Testimonial />
      <VideoPlay />
      <Footer />
    </div>
  );
}

export default App;
