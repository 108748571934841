const VideoPlay = () =>{
    return(
        <div className="container video-play">
      <iframe
        width="100%"
        height="415"
        src="https://www.youtube.com/embed/64L0ljaRCUE?autoplay=1"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    )
}

export default VideoPlay;