import React from "react";

const Gallery = () => {
  return (
    <div className="as-mainwrapper">
      <div className="bg-white">
        <div className="breadcrumb-banner-area">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcrumb-text">
                  <h1 className="text-center">Gallery</h1>
                  <div className="breadcrumb-bar">
                    <ul className="breadcrumb text-center">
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>Gallery</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gallery-area pt-100 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6">
                <div className="single-gallery-img mb-30">
                  <a
                    href="assets/img/gallery/gallery1.jpg"
                    data-fancybox="images"
                  >
                    <img src="assets/img/gallery/gallery1.jpg" alt="" />
                  </a>
                </div>
              </div>

              <div className="col-md-6 col-sm-6">
                <div className="single-gallery-img mb-30">
                  <a
                    href="assets/img/gallery/gallery2.jpg"
                    data-fancybox="images"
                  >
                    <img src="assets/img/gallery/gallery2.jpg" alt="" />
                  </a>
                </div>
              </div>

              <div className="col-md-4 col-sm-6">
                <div className="single-gallery-img mb-30">
                  <a
                    href="assets/img/gallery/gallery3.jpg"
                    data-fancybox="images"
                  >
                    <img src="assets/img/gallery/gallery3.jpg" alt="" />
                  </a>
                </div>
              </div>

              <div className="col-md-4 col-sm-6">
                <div className="single-gallery-img mb-30">
                  <a
                    href="assets/img/gallery/gallery4.jpg"
                    data-fancybox="images"
                  >
                    <img src="assets/img/gallery/gallery4.jpg" alt="" />
                  </a>
                </div>
              </div>

              <div className="col-md-4 col-sm-6">
                <div className="single-gallery-img mb-30">
                  <a
                    href="assets/img/gallery/gallery5.jpg"
                    data-fancybox="images"
                  >
                    <img src="assets/img/gallery/gallery5.jpg" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div style={{ textAlign: "center" }} className="about-container">
              <a
                className="button-default"
                href="https://www.instagram.com/kornerston_academy/"
              >
                view more
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
