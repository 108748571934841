
import { color } from "@mui/system";
import React, { useEffect,useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import YouTubeIcon from '@mui/icons-material/YouTube';

const Contact = () => {

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [msgError, setMsgError] = useState("");
  const [loader, setLoader] = useState(false);
  const [apiCall,setApiCall]= useState(0);
  const [successData,setSuccessData]= useState("");
  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('sm');


  const search = window.location.search;
  const params = new URLSearchParams(search);
  const msg = params.get('msg');
  //console.log(foo);

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  function handleChange(event) {

  if(event.target.name==='name'){
    setName(event.target.value);
  }
  if(event.target.name==='phone'){
    setPhone(event.target.value);
  }
  if(event.target.name==='email'){
    setEmail(event.target.value);
  }
  if(event.target.name==='message'){
    setMessage(event.target.value);
  }

  //console.log(event.target.name, "== " , event.target.value);
  
}

function handleSubmit(){
  // setType(false);
  // if(name!=="" && email !=="" && phone !==""){
  //   setType(true);
  // }
  
  if(name===""){
    setError("Please enter name field");
    return false;
  }else if(email===""){
    setEmailError("Please enter email field");
    return false;
  }else if(phone===""){
    setPhoneError("Please enter phone field");
    return false;
  }
  // else if(message===""){
  //   setMsgError("Please enter message field");
  //   return false;
  // }
  else{
    setLoader(true);
    axios.post('https://api.kornerston.com/sendMail', {
      email: email,
      name : name,
      message : message,
      phone : phone
      
    })
    .then(function (response) {
      if(response.data.status === 1){
        setLoader(false);
        setOpen(true);
      }
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    })
  }
  //return false;
  
  
}

  return (
    <div className="as-mainwrapper">
      <div className="bg-white">
        {/* <div className="breadcrumb-banner-area">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcrumb-text">
                  <h1 className="text-center">Contact</h1>
                  <div className="breadcrumb-bar">
                    <ul className="breadcrumb text-center">
                      <li>
                        <a href="index.html">Home</a>
                      </li>
                      <li>Contact</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {open===true ? 
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          onClose={handleClose}
        >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText style={{color:"green"}}>
            Your information sent successfully!
          </DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
            {/* <FormControl sx={{ mt: 2, minWidth: 120 }}>
              <InputLabel htmlFor="max-width">maxWidth</InputLabel>
              <Select
                autoFocus
                value={maxWidth}
                onChange={handleMaxWidthChange}
                label="maxWidth"
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                <MenuItem value={false}>false</MenuItem>
                <MenuItem value="xs">xs</MenuItem>
                <MenuItem value="sm">sm</MenuItem>
                <MenuItem value="md">md</MenuItem>
                <MenuItem value="lg">lg</MenuItem>
                <MenuItem value="xl">xl</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              sx={{ mt: 1 }}
              control={
                <Switch checked={fullWidth} onChange={handleFullWidthChange} />
              }
              label="Full width"
            /> */}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
        </Dialog>
        :""}

        {loader ? <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
        :
        <div className="contact-area pt-50 pb-20">
          <div className="container">
            <div className="row g-0 mt-100">
              <div className="col-md-12 col-lg-6 blue-bg">
                <div className="edubuzz-address">
                  <h2 className="contact-title">Contact Us</h2>
                  {/* <p>
                    Claritas est etiam processus dynamicus, qui sequitur
                    mutationem consuetudium lectorum. Mirum est notare quam
                    littera gothica, quam nunc putamus parum claram anteposuerit
                    litterarum formas human.
                  </p> */}
                  <ul>
                    <li>
                      {/* <i className="fa fa-fax"></i> */}
                      <h4 className="text-white">KORNERSTON ACADEMY</h4>
                    </li>
                    <li>
                      <i className="fa fa-phone"></i> Phone : +91 9841603337,
                      +91 9841506333
                    </li>
                    <li>
                      <i className="fa fa-envelope-o"></i> Web:
                      info@kornerston.com
                    </li>
                    <li>
                      <i className="fa fa-globe"></i> Web:
                      info@kornerston.com
                    </li>
                    <li>
                      {/* <i className="fa fa-fax"></i> Address : 202/190, 1st Floor
                      Velachery Main Road Santhosapuram, Chennai – 600073 */}
                    
                       <div className="d-flex">
                       <div> <i class="fa fa-map-marker"></i></div>
                       <div>
                       Address : 202/190, 1st Floor<br></br>
                      Velachery Main Road<br></br> Santhosapuram,<br></br> Chennai – 600073<br></br>
                      Landmark:Between Ruby Elites and<br></br>
                      Santhosapuram Bus Stop
                       </div>
                       </div>
                        
                    </li>
                    
                  </ul>
                  <div className="contact-social">
                    <h3>
                      <strong>Also Can Find Us</strong>
                    </h3>
                    <ul>
                      <li>
                        <a href="https://www.facebook.com/kornerstonacademy/">
                          <i className="zmdi zmdi-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/kornerstonA">
                          <i className="zmdi zmdi-twitter"></i>
                        </a>
                      </li>
                      
                      {/* <li>
                        <a href="#">
                          <i className="zmdi zmdi-google-plus"></i>
                        </a>
                      </li> */}
                      <li>
                        <a href="https://www.instagram.com/kornerston_academy/">
                          <i className="zmdi zmdi-instagram"></i>
                        </a>
                      </li>
                      <li>
                      <a
                  href="https://youtu.be/64L0ljaRCUE"
                  target="_blank"
                >
                  <YouTubeIcon />
                  </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 yellow-bg">
                <div className="contact-form-wrap">
                  {/* <span>{msg ==='success'?"Mail sent succesfully!":""}</span> */}
                  {/* {open===true && msg ==='success' ? 
                  <div>
                  <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert onClose={() => { setOpen(false)}}>Mail sent succesfully!</Alert>
                  </Stack>
                  </div>
                  :""} */}
                  <h2 className="contact-title">SEND YOUR MESSAGE</h2>
                  {/* <form
                    id="contact-form"
                    action="http://localhost:4000/sendMail"
                    method="post"> */}
                    <div className="row">
                      <div className="col-12">
                        <div className="contact-form-style mb-20">
                          <input name="name" placeholder="Name*" type="text"
                          required
                          value={name}
                          onChange={(e)=>handleChange(e)}
                           />
                           {
                             error!=="" ? <span style={{color:"red"}}>{error}</span> :""
                           }
                           
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="contact-form-style mb-20">
                          <input
                            name="phone"
                            placeholder="Phone*"
                            type="text"
                            required
                            value={phone}
                            onChange={(e)=>handleChange(e)}
                          />
                          {
                             phoneError!=="" ? <span style={{color:"red"}}>{phoneError}</span> :""
                           }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="contact-form-style mb-20">
                          <input
                            name="email"
                            placeholder="Email*"
                            type="email"
                            required
                            value={email}
                            onChange={(e)=>handleChange(e)}
                          />
                          `{
                             emailError!=="" ? <span style={{color:"red"}}>{error}</span> :""
                           }
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="contact-form-style">
                          <textarea
                            name="message"
                            placeholder="Type your message here.."
                            value={message}
                            onChange={(e)=>handleChange(e)}
                          ></textarea>
                          
                          <button className="button-default" type="submit"
                            onClick={handleSubmit}
                          >
                          <span>Send Email</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  {/* </form> */}
                  <p className="form-messege"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    </div>
  );
};

export default Contact;
