import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Switch,
  Link,
} from "react-router-dom";
// import AppBar from "@mui/material/AppBar";
// import Box from "@mui/material/Box";
// import Toolbar from "@mui/material/Toolbar";
// import IconButton from "@mui/material/IconButton";
// import Typography from "@mui/material/Typography";
// import Menu from "@mui/material/Menu";
// import MenuIcon from "@mui/icons-material/Menu";
// import Container from "@mui/material/Container";
// import Avatar from "@mui/material/Avatar";
// import Button from "@mui/material/Button";
// import Tooltip from "@mui/material/Tooltip";
// import MenuItem from "@mui/material/MenuItem";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import "bootstrap/dist/css/bootstrap.min.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import YouTubeIcon from '@mui/icons-material/YouTube';

const pages = ["Home", "Courses", "About", "Blog"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement("script");
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}

const TopNav = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [link1, setLink1] = useState(false);
  const [link2, setLink2] = useState(false);
  const [link3, setLink3] = useState(false);
  const [link4, setLink4] = useState(false);
  const [link5, setLink5] = useState(false);
  const [link6, setLink6] = useState(false);
  const [link7, setLink7] = useState(false);
  const [link8, setLink8] = useState(false);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const pathname = window.location.pathname;
  console.log(pathname);
  useEffect(() => {
    if (pathname == "/") {
      setLink1(true);
    } else if (pathname === "/features") {
      setLink2(true);
    } else if (pathname === "/courses") {
      setLink3(true);
    } else if (pathname === "/blogs") {
        setLink8(true);
    } else if (pathname === "/overseas-education") {
      setLink4(true);
    } else if (pathname === "/gallery") {
      setLink5(true);
    } else if (pathname === "/about") {
      setLink6(true);
    } else if (pathname === "/contact") {
      setLink7(true);
    }
  }, [pathname]);

  return (
    <>
      <div class="header-top">
        <div class="container">
          <div className="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
              {/* <span>Have any question? +968 547856 254</span> */}
              <div className="social-icons">
                <a
                  href="https://www.facebook.com/kornerstonacademy/"
                  target="_blank"
                >
                  <i className="zmdi zmdi-facebook"></i>
                </a>
                <a href="https://twitter.com/kornerstonA" target="_blank">
                  <i className="zmdi zmdi-twitter"></i>
                </a>
                <a
                  href="https://www.instagram.com/kornerston_academy/"
                  target="_blank"
                >
                  <i className="zmdi zmdi-instagram"></i>
                </a>
                <a
                  href="https://youtu.be/64L0ljaRCUE"
                  target="_blank"
                >
                  <YouTubeIcon />
                  {/* <i className="zmdi zmdi-youtube"></i> */}
                </a>
              </div>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
              <div class="header-top-right">
                {/* <span>Phone: +85 4856 5478</span>
                <span>Email: info@example.com</span> */}
                <div className="social-icons">
                  <a href="tel:+9198416 03337" target="_blank">
                    <i className="zmdi zmdi-phone"></i>
                  </a>
                  <a href="https://wa.me/919841603337" target="_blank">
                    <i className="zmdi zmdi-whatsapp"></i>
                  </a>
                  <a href="mailto:info@kornerston.com" target="_blank">
                    <i className="zmdi zmdi-email"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="light"
        fixed="top"
      >
        <Container>
          <div className="navbar-main">
            <div>
              <Navbar.Brand href="/">
                <img
                  src="/assets/img/logo/kornerston.png"
                  alt=""
                  className="d-inline-block align-top navbar-image"
                  
                />
              </Navbar.Brand>
            </div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" className="responsive-navbar" />
            <div>
              <Navbar.Collapse id="responsive-navbar-nav">
                {/* <Nav className="me-auto"></Nav> */}

                <Nav className="items-center nav-elements">
                  <Nav.Link
                    href="/"
                    className={`px-2 ${link1 ? "active-link" : "empty"}`}
                  >
                    Home
                  </Nav.Link>

                  <Nav.Link
                    href="/about"
                    className={`px-1 ${link6 ? "active-link" : "empty"}`}
                  >
                    About
                  </Nav.Link>

                  <Nav.Link
                    href="/courses"
                    className={`px-1 ${link3 ? "active-link" : "empty"}`}
                  >
                    Courses
                  </Nav.Link>
                  <Nav.Link
                    href="/overseas-education"
                    className={`px-1 ${link4 ? "active-link" : "empty"}`}
                  >
                    Overseas Education
                  </Nav.Link>

                  <Nav.Link
                    href="/features"
                    className={`px-1 ${link2 ? "active-link" : "empty"}`}
                  >
                    Features
                  </Nav.Link>

                  <Nav.Link
                    href="/gallery"
                    className={`px-1 ${link5 ? "active-link" : "empty"}`}
                  >
                    Gallery
                  </Nav.Link>
                  <Nav.Link
                    href="/blogs"
                    className={`px-1 ${link8 ? "active-link" : "empty"}`}
                  >
                    Blogs
                  </Nav.Link>
                  <Nav.Link
                    href="/contact"
                    className={`px-1 ${link7 ? "active-link" : "empty"}`}
                  >
                    Contact
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </div>
          </div>
        </Container>
      </Navbar>
    </>
  );
};
export default TopNav;
