import { useEffect } from "react";
import SimpleSlider from "./components/Banner";
import Hero1 from "./components/Hero1";
import TopNav from "./components/NavBar/Nav";
import Footer from "./components/NavBar/Footer";
import Courses from "./components/Courses/courses";
import About from "./components/About";
import Contact from "./components/Contact/Contact";

function ContactAll() {
  useEffect(() => {
    document.title = "Contact us - Kornerston Academy";
  });

  return (
    <div>
      <TopNav />
      {/* <SimpleSlider />
      <Hero1 />
      <Courses /> */}

      {/* <CoursesDetails /> */}
      {/* <About /> */}
      <Contact />
      <div style={{ height: "400px" }}>
        <iframe
          // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3910.9225995115007!2d76.70543431462869!3d11.413163991891361!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8bd4821f33497%3A0xd04ebb4ec752ca4c!2sTamil%20Nadu%20Tourism%20Development%20Corporation%20Unit%202!5e0!3m2!1sen!2sin!4v1648477451550!5m2!1sen!2sin"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31110.53730344421!2d80.171832!3d12.919470000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb9206341ece65c7b!2sKornerston%20Academy!5e0!3m2!1sen!2sin!4v1658226920619!5m2!1sen!2sin"
          width="100%"
          height="100%"
          frameBorder={0}
          title="map"
          allowFullScreen
          aria-hidden="false"
          tabIndex={0}
          loading="lazy"
        />
      </div>

      <Footer />
    </div>
  );
}

export default ContactAll;
