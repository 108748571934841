import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Autocomplete } from "@mui/material";

const blogData = [
  {
    id: 1,
    auto: "IELTS Exams",
    title: "Tips for Success IELTS Exams",
    description:
      "IELTS (The International English Language Testing System) is the world’s most popular English language proficiency test for higher education and global migration.",
    imageSrc: "assets/img/slider/6.jpg",
    link: "/blogs/tips-for-success-IELTS-exams",
  },
  {
    id: 2,
    auto: "PTE Exams",
    title: "How to Prepare for PTE Exams",
    description:
      "The PTE Academic is a computer-based academic English language test aimed at non-native English speakers wanting to study abroad and immigration.",
    imageSrc: "assets/img/slider/thumbnail.png",
    link: "/blogs/how-to-prepare-for-PTE-exams",
  },
  {
    id: 3,
    auto: "NAATI CCL",
    title: "All You Need to Know About NAATI CCL",
    description:
      "NAATI CCL - National Accreditation Authority for Translators and Interpreters - Credentialed Community Language Test is an assessment of language abilities at a community level that gives 5 points for Australia immigration visa application.",
    imageSrc: "assets/img/slider/naaticcl (1).png",
    link: "/blogs/all-you-need-to-know-about-NAATI-CCL",
  },
  {
    id: 4,
    auto: "TOEFL",
    title: "All You Need to Know About TOEFL",
    description:
      "TOEFL stands for Test of English as a Foreign Language. It is a standardized test designed to assess the English language proficiency of non-native English speakers who are planning to study or work in English-speaking environments.",
    imageSrc: "assets/img/slider/All You Need to About TOEFL - Thumbnail.png",
    link: "/blogs/all-you-need-to-know-about-TOEFL",
  },
  {
    id: 5,
    auto: "Duolingo English",
    title: "Duolingo English Test",
    description:
      "The Duolingo English Test is used as proof of English proficiency by institutions and employers around the world.",
    imageSrc: "assets/img/slider/Duolingo English Test - Thumbnail (1).png",
    link: "/blogs/duolingo-english-test",
  },
  {
    id: 6,
    auto: "Occupational English Test (OET)",
    title: "What is Occupational English Test (OET)?",
    description:
      "The Occupational English Test (OET) is an English language proficiency test specifically designed for healthcare professionals.",
    imageSrc: "assets/img/slider/OET Thumbnail.png",
    link: "/blogs/occupational-english-test",
  },
];

export default function Blogs() {
  const [selectedName, setSelectedName] = useState(null);
  const [focusedBlog, setFocusedBlog] = useState(null);

  const handleClearFocus = () => {
    setFocusedBlog(null);
  };

  const scrollToBlog = (blog) => {
    const blogElement = document.getElementById(blog.auto);
    if (blogElement) {
      blogElement.scrollIntoView({ behavior: "smooth" });
      setFocusedBlog(blog);
    }
  };

  return (
    <div className="course-area section-padding">
      <div className="container">
        <div className="mt-100 text-center blogs-title">
          <h3>
            Search For <span className="orange-color">Blogs</span>
          </h3>
          <div className="blog-text">
            <Autocomplete
              id="blog-search"
              options={blogData}
              getOptionLabel={(option) => option.auto}
              value={selectedName}
              onChange={(event, newValue) => {
                setSelectedName(newValue);
                if (newValue) {
                  scrollToBlog(newValue);
                }
              }}
              onBlur={handleClearFocus}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search By Blog Title"
                  variant="outlined"
                  style={{ width: "300px" }}
                />
              )}
            />
          </div>
        </div>

        <div className="row">
          {blogData.map((blog) => (
            <div className="col-lg-4 col-md-6 col-12" key={blog.id}>
              <div
                className={`single-item-blogs-main mb-50 ${
                  focusedBlog?.id === blog.id ? "focused" : ""
                }`}
                style={{ boxShadow: "none" }}
                id={blog.auto} // Assign an id to the div
              >
                <div className="single-item-image overlay-effect">
                  <a href={blog.link}>
                    <img src={blog.imageSrc} alt="" />
                  </a>
                </div>
                <div className="single-item-text">
                  <h4 style={{ height: "50px" }}>
                    <a href={blog.link}>{blog.title}</a>
                  </h4>
                  <p
                    style={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      WebkitLineClamp: 3,
                      textOverflow: "ellipsis",
                    }}
                  >
                    {blog.description}
                  </p>
                  <div className="single-item-content">
                    <div className="single-item-rating1">
                      <a href={blog.link}>Learn Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
