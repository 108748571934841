import * as React from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";

// const ExpandMore = styled((props) => {
//   const { expand, ...other } = props;
//   return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//   transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
//   marginLeft: "auto",
//   transition: theme.transitions.create("transform", {
//     duration: theme.transitions.duration.shortest,
//   }),
// }));

const useStyles = makeStyles({
  root: {},
  cardHead: {
    fontWeight: "bold",
    fontSize: "22px",
    color: "#1d1d1ede",
  },
  cardBody: {
    fontWeight: "600",
    fontSize: "13px",
    color: "#4c4c4cde",
  },
  userCount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: "12px",
    padding: "0px 8px",
  },
  favCount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

const courses = [
  {
    id: 1,
    title: "IELTS",
    description: "IELTS (The International English Language Testing System) is the world’s most popular English language proficiency test for higher education and global migration.",
    imageSrc: "assets/img/slider/6.jpg",
    link: "/courses-details/ielts"
  },
  {
    id: 2,
    title: "PTE ACADEMIC",
    description: "The PTE Academic is a computer-based academic English language test aimed at non-native English speakers wanting to study abroad and immigration.",
    imageSrc: "assets/img/slider/5.jpg",
    link: "/courses-details/pte"
  },
  {
    id: 3,
    title: "TOEFL",
    description: "The TOEFL iBT® test measures your ability to use and understand English at the university level. It evaluates how well you combine your reading, listening, speaking, and writing skills.",
    imageSrc: "assets/img/slider/7.jpg",
    link: "/courses-details/toefl"
  },
  {
    id: 4,
    title: "OET",
    description: "OET - The Occupational English Test is the English language test for healthcare professionals. It assesses the language communication skills of healthcare professionals.",
    imageSrc: "assets/img/slider/8.jpg",
    link: "/courses-details/oet"
  },
  {
    id: 5,
    title: "CELPIP",
    description: "CELPIP is Canadian English Language Proficiency Index Program. The CELPIP General and CELPIP General LS tests are accredited by Immigration Refugees and Citizenship Canada (IRCC) to assess the language proficiency in English.",
    imageSrc: "assets/img/slider/9.jpg",
    link: "/courses-details/celpip"
  },
  {
    id: 6,
    title: "NAATI CCL - TAMIL",
    description: "NAATI CCL - National Accreditation Authority for Translators and Interpreters - Credentialed Community Language Test is an assessment of language abilities at a community level that gives 5 points for Australia immigration visa application.",
    imageSrc: "assets/img/slider/10.jpg",
    link: "/courses-details/naati-ccl"
  }
]

export default function CoursesFull() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="course-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title-wrapper">
              <div className="course-header section-title">
                <h3>COURSES</h3>
                {/* <p>There are many variations of passages of Lorem Ipsum</p> */}
              </div>
            </div>
          </div>
        </div>
        <div class="row">

        {courses.map((course, index) => (
        <div className="col-lg-4 col-md-6 col-12" key={index}>
          <div className="single-item mb-50">
            <div className="single-item-image overlay-effect">
              <a href={course.link}>
                <img src={course.imageSrc} alt="" />
              </a>
            </div>
            <div className="single-item-text">
              <h4>
                <a href={course.link}>{course.title}</a>
              </h4>
              <p>{course.description}</p>
              <div className="single-item-content">
                {/* Add content here if needed */}
              </div>
            </div>
            <div className="single-item-rating1">
              <a href={course.link}>Learn Now</a>
            </div>
          </div>
        </div>
      ))}
      
   
        </div>
    
      </div>
    </div>
  );
}
