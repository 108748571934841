import React from "react";
import YouTubeIcon from '@mui/icons-material/YouTube';
// import "./bootstrap.module.css";

const Footer = () => {
  return (
    <div>
      <footer>
        {/* <div className="newsletter-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-5">
                <div className="newsletter-content">
                  <h3>SUBSCRIBE</h3>
                  <h2>TO OUR NEWSLETTER</h2>
                </div>
              </div>
              <div className="col-lg-7 col-md-7">
                <div className="newsletter-form angle">
                  <form
                    action=""
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    className="mc-form footer-newsletter fix"
                  >
                    <div className="subscribe-form">
                      <input
                        id="mc-email"
                        type="email"
                        // autocomplete
                        placeholder="Enter your email here"
                      />
                      <button id="mc-submit" type="submit">
                        SUBSCRIBE
                      </button>
                    </div>
                  </form>

                  <div className="mailchimp-alerts text-centre fix pull-right">
                    <div className="mailchimp-submitting"></div>
                    <div className="mailchimp-success"></div>
                    <div className="mailchimp-error"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="footer-widget-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="single-footer-widget">
                  <h3>Follow us on</h3>
                  <div className="social-icons">
                    <a
                      href="https://www.facebook.com/kornerstonacademy/"
                      target="_blank"
                    >
                      <i className="zmdi zmdi-facebook"></i>
                    </a>
                    <a href="https://twitter.com/kornerstonA" target="_blank">
                      <i className="zmdi zmdi-twitter"></i>
                    </a>
                    {/* <a href="#">
                      <i className="zmdi zmdi-rss"></i>
                    </a>
                    <a href="#">
                      <i className="zmdi zmdi-google-plus"></i>
                    </a>
                    <a href="#">
                      <i className="zmdi zmdi-pinterest"></i>
                    </a> */}
                    <a
                      href="https://www.instagram.com/kornerston_academy/"
                      target="_blank"
                    >
                      <i className="zmdi zmdi-instagram"></i>
                    </a>
                    <a
                  href="https://youtu.be/64L0ljaRCUE"
                  target="_blank"
                >
                  <YouTubeIcon />
                  {/* <i className="zmdi zmdi-youtube"></i> */}
                </a>
                  </div>
                  <div className="footer-logo">
                    <a href="index.html">
                      <img src="img/logo/footer.png" alt="" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-footer-widget">
                  <h3>GET IN TOUCH</h3>
                  <a href="tel:555-555-1212">
                    <i className="fa fa-phone"></i>+91 9841603337, +91
                    9841506333
                  </a>
                  <span>
                    <i className="fa fa-envelope"></i>info@kornerston.com
                  </span>
                  <span>
                    <i className="fa fa-globe"></i>kornerston.com
                  </span>
                  <span>
                  <div className="d-flex">
                       <div> <i class="fa fa-map-marker"></i></div>
                       <div>
                       Address : 202/190, 1st Floor<br></br>
                      Velachery Main Road<br></br> Santhosapuram,<br></br> Chennai – 600073<br></br>
                      Landmark:Between Ruby Elites and<br></br>
                      Santhosapuram Bus Stop
                       </div>
                       </div>
                  </span>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-footer-widget">
                  <h3>Useful Links</h3>
                  <ul className="footer-list">
                    {/* <li>
                      <a href="#">Teachers &amp; Staff</a>
                    </li> */}
                    <li>
                      <a href="/courses">Our Courses</a>
                    </li>
                    {/* <li>
                      <a href="#">Courses Categories</a>
                    </li> */}
                    {/* <li>
                      <a href="#">Terms &amp; Conditions</a>
                    </li> */}
                    <li>
                      <a href="/about">About</a>
                    </li>
                    <li>
                      <a href="/contact">Support</a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-6">
                <div className="single-footer-widget">
                  <h3>Instagram</h3>
                  <ul id="Instafeed"></ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="footer-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-7 col-12">
                <span>
                  Copyright 2022 &copy; Kornerston Academy. All right reserved.
                  {/* <a href="#"> FUZIONEST Private Limited</a> */}
                </span>
              </div>
              {/* <div className="col-lg-6 col-md-5 col-12">
                <div className="column-right">
                  <span>Privacy Policy , Terms &amp; Conditions</span>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
