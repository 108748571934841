import * as React from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import TopNav from "../NavBar/Nav";
import Footer from "../NavBar/Footer";

const useStyles = makeStyles({
  root: {},
  cardHead: {
    fontWeight: "bold",
    fontSize: "22px",
    color: "#1d1d1ede",
  },
  cardBody: {
    fontWeight: "600",
    fontSize: "13px",
    color: "#4c4c4cde",
  },
  userCount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: "12px",
    padding: "0px 8px",
  },
  favCount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export default function BlogsLinkOET() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <TopNav />
      <div class="course-area section-padding">
        <div class="container">
          <div class="row mt-50">
            <div class="col-lg-8 col-md-6 col-12">
              <div className="mt-100 blogs-title">
                <h3>What is Occupational English Test (OET)?</h3>
                {/* <em>Introduction</em> */}
              </div>
              <div class="single-item1">
                <div class="single-item-image">
                  <img src="/assets/img/slider/OET (1).png" alt="" />
                </div>
                <div class="single-item-text1">
                  <p>
                    The Occupational English Test (OET) is an English language
                    proficiency test specifically designed for healthcare
                    professionals. It assesses the language skills of healthcare
                    professionals who seek to practice in an English-speaking
                    environment, primarily for countries such as Australia, New
                    Zealand, Singapore, and the United Kingdom. OET measures the
                    ability of candidates to communicate effectively in a
                    healthcare setting, focusing on language skills relevant to
                    their profession.
                  </p>

                  <br></br>

                  <p>
                  <a href = "https://oet.com/" style={{color:'blue'}}>OET</a> is widely accepted by regulatory healthcare bodies,
                    employers, and educational institutions as evidence of
                    English proficiency for healthcare professionals. It
                    provides a realistic and task-based assessment, ensuring
                    that candidates can understand and communicate effectively
                    in the workplace situations they are likely to encounter in
                    their professional roles.
                  </p>

                  <p>
                    The test is recognized by various healthcare boards and
                    councils as proof of English language proficiency, and
                    successful completion of the OET can enhance the career
                    prospects of healthcare professionals seeking opportunities
                    in English-speaking countries. The test is divided into four
                    sub-tests.
                  </p>

                  <h3>English for Healthcare Professionals Exam Structure</h3>

                  <h4>1. Listening (approximately 50 minutes):</h4>
                  <ul className="oet-blogs">
                    <li>Consists of three parts.</li>
                    <li>
                      Part A assesses the ability to identify specific
                      information during a consultation or interview.
                    </li>
                    <li>
                      Part B evaluates the ability to understand the main ideas
                      and detailed information in a short talk or presentation.
                    </li>
                    <li>
                      Part C assesses the ability to understand the speaker's
                      opinion, attitude, or purpose in a healthcare-related
                      conversation.
                    </li>
                  </ul>

                  <h4>2. Reading (60 minutes):</h4>
                  <ul className="oet-blogs">
                    <li>Consists of three parts.</li>
                    <li>
                      Part A assesses the ability to skim and scan texts for
                      specific information.
                    </li>
                    <li>
                      Part B evaluates the ability to understand the main ideas
                      and detailed information in a text.
                    </li>
                    <li>
                      Part C assesses the ability to identify detailed
                      information, main ideas, and opinions in a longer, more
                      complex text.
                    </li>
                  </ul>

                  <h4>3. Writing (45 minutes):</h4>
                  <ul className="oet-blogs">
                    <li>
                      Requires candidates to write a letter, usually a referral
                      letter, discharge letter, or a letter of advice, based on
                      case notes provided.
                    </li>
                    <li>
                      The writing task assesses the ability to organize ideas,
                      use appropriate language, and communicate effectively in a
                      professional healthcare context.
                    </li>
                  </ul>

                  <h4>4. Speaking (approximately 20 minutes):</h4>
                  <ul className="oet-blogs">
                    <li>Consists of two role-play tasks.</li>
                    <li>
                      The first task simulates a consultation between a
                      healthcare professional and a patient, where the candidate
                      takes on the role of the healthcare professional.
                    </li>
                    <li>
                      The second task simulates a discussion between healthcare
                      professionals, where the candidate takes on the role of a
                      healthcare professional.
                    </li>
                  </ul>

                  <p>
                    It&#39;s important to note that the OET is specifically
                    tailored to different healthcare professions, such as
                    medicine, nursing, dentistry, pharmacy, and veterinary
                    science. The content of the test materials and scenarios is
                    designed to reflect the communication skills required in
                    each specific healthcare discipline. Candidates should
                    prepare for the OET with this in mind, focusing on their
                    particular professional context.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mt-100">
              <div class="single-item mb-50">
                <div class="single-item-blogsLink">
                  <h4>Courses</h4>
                </div>
                <div class="single-item-text">
                  <h4>
                    <a href="/courses-details/toefl">IELTS</a>
                  </h4>
                  <small>(International English Language Testing System)</small>
                  <h4>
                    <a href="/courses-details/toefl">PTE ACADEMIC</a>
                  </h4>
                  <small>(PTE Academic)</small>
                  <h4>
                    <a href="/courses-details/toefl">TOEFL</a>
                  </h4>
                  <h4>
                    <a href="/courses-details/toefl">OET</a>
                  </h4>
                  <small>(The Occupational English Test)</small>
                  <h4>
                    <a href="/courses-details/toefl">CELPIP</a>
                  </h4>
                  <small>
                    (Canadian English Language Proficiency Index Program)
                  </small>
                  <h4>
                    <a href="/courses-details/toefl">NAATI CCL - TAMIL</a>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-sm-12 text-center">
              <a href="/courses" class="button-default button-large">
                Browse All Courses <i class="zmdi zmdi-chevron-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
